import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, TemplateRef } from '@angular/core';

export interface Toast {
	header: string;
	body?: string;
	classname?: string;
	delay?: number;
}

@Injectable({ providedIn: 'root' })
export class ToastService {
	isBrowser = false;
	toasts: Toast[] = [];

	constructor(@Inject(PLATFORM_ID) private platformId: Object) {
		this.isBrowser = isPlatformBrowser(platformId);
	}
	show(toast: Toast) {
		if (this.isBrowser) {
			this.toasts.push(toast);
		}
	}

	remove(toast: Toast) {
		if (this.isBrowser) {
			this.toasts = this.toasts.filter((t) => t !== toast);
		}
	}

	clear() {
		if (this.isBrowser) {
			this.toasts.splice(0, this.toasts.length);
		}
	}
}