import { Observable } from "rxjs";
import { ActivityBooking } from "./activity_booking";
import { Entity, ModelI, } from "./model";

@Entity({
    url: "/api/v1/order"
})
export class Order implements ModelI {
    id!: number;
	number!: string;
	control_number!: string;
	description!: string;
	activity_booking_id!: number;
	supplier_id!: number;
	person_id!: number;
	status!: number;
	gross_amount!: number;
	tax_amount!: number;
	amount!: number;
	currency!: string;
	base_amount!: number;
	exchange_rate!: number;
	base_currency!: string;
	issue_date!: string;
	due_date!: string;
	client_notes!: string;
	paid!: boolean;
	invoiced!: boolean;
	created_at!: string;
	// created_by!: string;
	updated_at!: string;
	// updated_by!: string;
	// deleted_at!: string;
	// deleted_by!: string;

    booking?: ActivityBooking;
    details!: any[];

	timeRemaining$!: Observable<number>;
}
