<app-header color="text-main" />

<main class="min-vh-100 py-5">
  <section class="container-fluid">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-7 col-md-9">
          <div class="text-center mb-5 mb-md-3">
            <h1 class="fs-3 fw-bolder" i18n>Register</h1>
            <div class="fw-medium mt-4" i18n>Let's create your account!</div>
            <div class="mt-3 mb-5 fw-light small">
              <span i18n>Already have an account?</span>
              <span>&nbsp;</span>
              <a routerLink="/login" class="text-main text-decoration-none" i18n>Login here</a>
            </div>
          </div>
          <form class="form needs-validation border rounded-4 p-5" novalidate [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="form-floating mb-3">
              <input type="text" id="username" class="form-control"
                [ngClass]="model.isInvalid('username') ? 'is-invalid' : ''" formControlName="username" required
                i18n-placeholder placeholder="Username" autocomplete="username">
              <label for="username" i18n>Username</label>

              <div *ngIf="model.isInvalid('username')" id="username-feedback" class="invalid-feedback">
                <div *ngIf="username.errors?.['required']" i18n>Username is required</div>
                <div *ngIf="username.errors?.['minlength']" i18n>Username must be at least 3 characters long.</div>
              </div>
            </div>

            <div class="form-floating mb-3">
              <input type="password" id="password" class="form-control"
                [ngClass]="model.isInvalid('password') ? 'is-invalid' : ''" formControlName="password" required
                i18n-placeholder placeholder="Password" autocomplete="new-password">
              <label for="password" i18n>Password</label>

              <div *ngIf="model.isInvalid('password')" id="password-feedback" class="invalid-feedback">
                <div *ngIf="password.errors?.['required']" i18n>Password is required</div>
                <div *ngIf="password.errors?.['minlength']" i18n>Password must be at least 8 characters long.</div>
              </div>
            </div>

            <div class="form-floating mb-3">
              <input type="text" id="name" class="form-control"
                [ngClass]="model.isInvalid('name') ? 'is-invalid' : ''" formControlName="name" required
                i18n-placeholder placeholder="Username" autocomplete="name">
              <label for="name" i18n>Name</label>

              <div *ngIf="model.isInvalid('name')" id="name-feedback" class="invalid-feedback">
                <div *ngIf="name.errors?.['required']" i18n>Name is required</div>
              </div>
            </div>

            <div class="form-floating mb-3">
              <input type="text" id="email" class="form-control"
                [ngClass]="model.isInvalid('email') ? 'is-invalid' : ''" formControlName="email" required
                i18n-placeholder placeholder="Username" autocomplete="email">
              <label for="email" i18n>Email</label>

              <div *ngIf="model.isInvalid('email')" id="email-feedback" class="invalid-feedback">
                <div *ngIf="email.errors?.['required']" i18n>Email is required</div>
              </div>
            </div>

            <button type="submit" class="btn btn-main text-white col-12 mt-3 py-3 rounded-4" [disabled]="!form.valid || loading.isLoading" i18n>
              Register
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</main>

<app-footer />