import { Component, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Activity } from '../../../models/activity';
import { getEnv } from '../../../services/config.service';
import { Fetchervice } from '../../../services/fetch.service';
import { RouterModule } from '@angular/router';
import { Rating } from '../../rating/rating';
import { LocaleService } from '../../../services/locale.service';

@Component({
  selector: 'card[square]',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    Rating,
  ],
  template: `
    @if (item) {
      <div class="card rounded-4">
        <div class="position-relative mx-3 mt-3">
          <span role="button" class="position-absolute pe-2 end-0 z-1" style="bottom: -14px;">
            @if (item.bookmarked) {
              <span class="text-white bg-main rounded-circle border border-main px-2 py-1 d-block" (click)="unbookmark(item)">
              <i class="fa-regular fa-heart"></i>  
              </span>
            }
            @else {
              <span class="text-danger bg-white rounded-circle border px-2 py-1 d-block" (click)="bookmark(item)">
                <i class="fa-regular fa-heart"></i>  
              </span>
            }
          </span>
          <picture class="position-relative overflow-hidden d-block rounded-3">
            <a class="text-decoration-none" [routerLink]="['/activity', item.id, item.slug]">
              @if (item.regularity_id == 1 || (item.tags && item.tags.length > 0)) {
                <span class="position-absolute top-0 start-0 mt-2 small-5 fw-900 text-uppercase ls-xl text-end w-100" style="margin-left: -12px;">
                  @if (item.regularity_id == 1) {
                    <span class="bg-white text-main shadow rounded-3 py-1 ps-3 pe-2 mb-1 d-block w-50" i18n>
                      Coming Soon
                    </span>
                  }
                  @if (item.tags && item.tags.length > 0) {
                    @for (t of item.tags; track t.id) {
                      <span class="bg-main text-white rounded-3 py-1 ps-3 pe-2 mb-1 d-block w-50">
                        {{ locale.getTagName(t.name) }}
                      </span>
                    }
                  }
                </span>
              }
              @if (item.images && item.images.length > 0) {
                <img class="card-img-top" [src]="item.images[0].path" [alt]="item.display_title" fetchpriority="low" rel="preload"
                  loading="lazy">
              }
              @else {
                <img class="card-img-top" src="https://loremflickr.com/270/180/nature" [alt]="item.display_title"
                  fetchpriority="low" rel="preload" loading="lazy">
              }
            </a>
          </picture>
        </div>
        <div class="card-body pb-0 pt-2">
          <a class="text-decoration-none text-reset" [routerLink]="['/activity', item.id, item.slug]">
            <div class="d-flex align-items-center text-muted small-2">
              <span><i class="fa-solid fa-location-dot"></i></span>
              <span class="px-2">{{ item.destination?.name }}</span>
              <!-- <i class="fa-solid fa-compass"></i>
              <span class="px-2">{{ item.category?.name }}</span> -->
            </div>
            <h3 class="fs-6 mt-2 fw-500 lh-base">
              {{ item.display_title }}
            </h3>
            <div class="d-flex align-items-center small mt-1 mb-2">
              <rating [rating]="item.rating" />
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span class="fw-light">
                <span>{{ item.rating | number:'1.1-1' }}</span>
                <span>/5</span>
                <span>&nbsp;</span>
                <span>({{ item.comments_count | number }})</span>
              </span>
            </div>
            <hr class="m-0">
            <div class="d-flex justify-content-between align-items-center small my-2">
              <div class="d-flex align-items-center">
                <span><i class="fa-regular fa-clock"></i></span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>{{ item.duration_quantity }}</span>
                <span>&nbsp;</span>
                <span>{{ item.duration_unit }}</span>
              </div>
              <div>
                <span i18n>From</span>
                <span>&nbsp;</span>
                <span class="fs-6 fw-500">
                  <span>{{ item.base_price | number:'1.2-2' }}</span>
                </span>
              </div>
            </div>
          </a>
        </div>
      </div>
    }
    @else {
      <div class="card rounded-4">
        <div class="bg-secondary-subtle m-3 rounded-3" style="height: 150px">&nbsp;</div>
        <div class="card-body pb-0 pt-2">
          <p class="bg-secondary-subtle rounded small-3">&nbsp;</p>
          <h3 class="fs-6 mt-2 bg-secondary-subtle rounded">&nbsp;</h3>
          <h3 class="fs-6 mt-2 bg-secondary-subtle rounded">&nbsp;</h3>
          <p class="bg-secondary-subtle rounded small-3 w-50 mt-3">&nbsp;</p>
          <hr class="m-0">
          <div class="d-flex justify-content-between align-items-center small my-2">
            <div class="bg-secondary-subtle rounded small-3" style="min-width: 60px;">&nbsp;</div>
            <div class="bg-secondary-subtle rounded small-3" style="min-width: 80px;">&nbsp;</div>
          </div>
        </div>
      </div>
    }
	`,
  host: { class: 'col' },
})
export class SquareArticle {
  fetch: Fetchervice<any> = Fetchervice.new<any>();

  @Input() item?: Activity;

  constructor(
    public locale: LocaleService
  ) {
    //
  }

  bookmark(activity: Activity) {
    activity.bookmarked = true;

    this.fetch.post(`${getEnv("API_URL")}/api/v1/activity/${activity.id}/bookmark`);
  }

  unbookmark(activity: Activity) {
    activity.bookmarked = false;

    this.fetch.post(`${getEnv("API_URL")}/api/v1/activity/${activity.id}/unbookmark`);
  }
}