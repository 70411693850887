import { Component, OnInit } from '@angular/core';
import {
  CommonModule, DatePipe,
} from '@angular/common'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { AuthService, LoginCredentialsI, RegisterCredentialsI } from '../../../services/auth.service';
import { ToastService } from '../../../services/toats.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../components/public/header/component';
import { FooterComponent } from '../../../components/public/footer/component';
import { LoadingService } from '../../../services/loading.service';
import { Validate } from '../../../services/validator';
import { RouteNamesService } from '../../../services/route_name.service';
import { MetaService } from '../../../services/meta.service';

@Component({
  selector: 'app-internal-login',
  standalone: true,
  imports: [
    CommonModule,
    HeaderComponent,
    FooterComponent,
    RouterModule,
    ReactiveFormsModule,
  ],
  providers: [
    DatePipe,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class ClientRegisterComponent implements OnInit {
  form = new FormGroup({
    username: new FormControl('', [Validate.required, Validate.minLength(3)]),
    password: new FormControl('', [Validate.required, Validate.minLength(8)]),
    email: new FormControl('', [Validate.required, Validate.email]),
    name: new FormControl('', [Validate.required, Validate.minLength(3), Validate.maxLength(124)]),
  });

  model: RegisterModel;
  // toastService = inject(ToastService);

  constructor(
    private auth: AuthService,
    public toastService: ToastService,
    public loading: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private routeNames: RouteNamesService,
    private date: DatePipe,
    private meta: MetaService,
  ) {
    this.meta.title($localize`Register`);
    this.model = new RegisterModel(this.form);
    // this.toastService.show({ header: "CONSTRUCTOR"})
  }

  ngOnInit(): void {
    this.loading.hide();
  }

  get username(): AbstractControl {
    return this.form.get('username') as AbstractControl;
  }

  get password(): AbstractControl {
    return this.form.get('password') as AbstractControl;
  }

  get name(): AbstractControl {
    return this.form.get('name') as AbstractControl;
  }

  get email(): AbstractControl {
    return this.form.get('email') as AbstractControl;
  }

  onSubmit() {
    this.loading.show();
    const scope = this.route.snapshot.data["scope"];

    this.auth.register(scope, this.form.value as RegisterCredentialsI)
      .then(() => {
        this.toastService.show({ header: $localize`Almost ready to Xplore`, body: $localize`Please check your email for the account activation link.`, classname: "bg-success text-light" });
        this.router.navigate([this.routeNames.path(this.auth.redirectTo())]);
      }, error => {
        this.toastService.show({ header: "ERROR", body: error.error?.message ?? "Error", classname: "bg-danger text-light", })
      }).finally(() => {
        this.loading.hide();
      })
  }
}

class RegisterModel {
  constructor(
    public form: FormGroup<{
      username: FormControl<string | null>;
      password: FormControl<string | null>;
      email: FormControl<string | null>;
      name: FormControl<string | null>;
    }>
  ) {
    //
  }

  isInvalid(name: "username" | "password" | "document_id" | "name" | "email"): boolean {
    const formControl = this.form.get(name);

    if (formControl) {
      return formControl.invalid && (formControl.dirty || formControl.touched)
    }

    return false;
  }
}