import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { getEnv } from './config.service';
import { AuthService } from './auth.service';
import { Response } from '../interfaces/response';
import { EntityMetadata, Metadata, Model, ModelI } from '../models/model';
import { SortDirection } from '../directive/sorteable_header.directive';
import { Router } from '@angular/router';
import { ToastService } from './toats.service';
import { LoadingService } from './loading.service';
import { Location } from '@angular/common'
import { FormGroup } from '@angular/forms';
import { RouteNamesService } from './route_name.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: "root",
})
export class Fetchervice<T extends ModelI> {
  params: { [key: string]: string } = {};
  pkName = "id";
  endpoint!: string;
  modelClass!: typeof Model;
  response!: Response<T>;
  attributes!: T;
  attrNames!: (Extract<keyof T, string>)[];
  form!: FormGroup;
  isNew = true;
  items: T[] = [];
  pagination: {
    current: Response<T>["currentPage"],
    last: Response<T>["lastPage"],
    pages: number[],
  } = {
    current: 0,
    last: 0,
    pages: [0]
  }
  redirectTo?: string;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private router: Router,
    private routeNames: RouteNamesService,
    private toastService: ToastService,
    private loading: LoadingService,
    private storage: StorageService,
  ) { }
  from(endpoint: string): this;

  from(from: typeof Model): this;

  from(from: string | typeof Model) {
    if (typeof from == "string") {
      this.endpoint = from;
    } else {
      this.modelClass = from;
      this.endpoint = this.entity.getUrl();
      this.attrNames = this.entity.getAttributeNames();
    }

    return this;
  }

  get entity(): any {
    return this.modelClass?.prototype;
  }

  where(key: keyof T, value: string) {
    (this.params as any)[key] = value;

    return this;
  }

  orderBy(key: keyof T | string, order: SortDirection = "asc") {
    this.params["orderBy"] = `${key as string}:${order}`;

    return this;
  }

  getOrderBy() {
    return this.params["orderBy"];
  }

  perPage(perPage = 15) {
    this.params["perPage"] = `${perPage}`;

    return this;
  }

  paginate(page: number, perPage?: number) {
    this.params["page"] = `${page}`;
    if (perPage) {
      this.params["perPage"] = `${perPage}`;
    }

    return this;
  }

  relations(relations: string[]) {
    this.params["with"] = relations.join(",");

    return this;
  }

  cache(bool: boolean = true) {
    this.params["cache"] = `${bool}`;

    return this;
  }

  get(after?: () => void, before?: () => void): Promise<Response<T>> {
    return new Promise((resolve, reject) => {
      this.loading.show();
      if (before) {
        before();
      }

      if (!this.endpoint) {
        throw new Error(`Invalid endpoint for ${this.modelClass ?? this.endpoint}`);
      }

      this.http.get<Response<T>>(`${getEnv("API_URL")}${this.endpoint}`, {
        params: this.params,
        headers: {
          "Authorization": `Bearer ${this.auth.bearer}`,
        },
      })
        .subscribe({
          next: (response) => {
            // const key = `${this.endpoint}/${new URLSearchParams(this.params).toString()}`;
            // if (this.params["cache"] == "true") {
            //   if (!this.storage.has(key)) {
            //     const model = {
            //       items: this.items,
            //       pagination: this.pagination,
            //     }

            //     this.storage.set(key, model);
            //   }
            //   const model = this.storage.get(key);
            //   this.items = model.items;
            //   this.pagination = model.pagination;
            // }
            this.response = response;
            if (response.data && Array.isArray(response.data)) {
              this.items = response.data.map(item => this.applyGetter(item));
  
              this.pagination = {
                current: response.currentPage,
                last: response.lastPage,
                pages: [],
              }
  
              for (let x = 1; x <= 3 && 0 < response.currentPage - x; x++) {
                this.pagination.pages.unshift(response.currentPage - x);
              }
  
              this.pagination.pages.push(response.currentPage);
  
              for (let x = 1; x <= 3 && x <= response.lastPage - response.currentPage; x++) {
                this.pagination.pages.push(response.currentPage + x);
              }

            } else {
              this.items = [response as any];
            }

            resolve(response);
          },
          error: (err: HttpErrorResponse) => {
            this.handleError(err);
            reject(err);
          },
          complete: () => {
            if (after) {
              after();
            }

            this.loading.hide();
          }
        })
    })
  }

  find(id: number, after?: () => void, before?: () => void) {
    return new Promise((resolve, reject) => {
      this.loading.show();
      if (before) {
        before();
      }

      if (!this.endpoint) {
        throw new Error("Invalid endpoint.");
      }

      this.http.get<T>(`${getEnv("API_URL")}${this.endpoint}/${id}`, {
        params: this.params,
        headers: {
          "Authorization": `Bearer ${this.auth.bearer}`,
        },
      })
        .subscribe({
          next: (response) => {
            this.attributes = response;

            this.updateForm();

            resolve(response);
          },
          error: (err: HttpErrorResponse) => {
            this.handleError(err);

            this.loading.hide();

            reject(err);
          },
          complete: () => {
            if (after) {
              after();
            }

            this.loading.hide();
          }
        })
    })
  }

  setForm(form: FormGroup) {
    this.form = form;

    return this;
  }

  updateForm() {
    if (this.form) {
      for (const key in this.form.value) {
        if (this.attributes) {
          const value = this.attributes[key as keyof T] ?? undefined;
          if (value) {
            (this.form.controls as any)[key].setValue(value);
          }
        } else {
          (this.form.controls as any)[key].setValue("");
        }
      }
    }

    this.isNew = false;
  }

  save(after?: () => void, before?: () => void) {
    return new Promise((resolve, reject) => {
      this.loading.show();
      if (before) {
        before();
      }

      let method: "post" | "patch";
      let url: string;

      if (this.isNew) {
        method = "post";
        url = `${getEnv("API_URL")}${this.endpoint}`;
      } else {
        method = "patch";
        url = `${getEnv("API_URL")}${this.endpoint}/${(this.attributes as any)[this.pkName]}`;
      }

      this.http[method](url, this.form.value, {
        params: this.params,
        headers: {
          "Authorization": `Bearer ${this.auth.bearer}`,
        },
      }).subscribe({
        next: (response) => {
          this.attributes = response as T;
          resolve(response);

          if (this.redirectTo) {
            this.router.navigate([this.redirectTo, (this.attributes as any)[this.pkName], "update"]);
          // } else {
          //   this.router.navigate([this.routeNames.path(this.auth.redirectTo())]);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.handleError(err);

          this.loading.hide();

          reject(err);
        },
        complete: () => {
          if (after) {
            after();
          }

          this.loading.hide();
        }
      });
    });
  }

  delete(id: number, after?: () => void, before?: () => void) {
    return new Promise((resolve, reject) => {
      this.loading.show();
      if (before) {
        before();
      }

      if (!this.endpoint) {
        throw new Error("Invalid endpoint.");
      }

      this.http.delete<T>(`${getEnv("API_URL")}${this.endpoint}/${id}`, {
        // params: this.params,
        headers: {
          "Authorization": `Bearer ${this.auth.bearer}`,
        },
      })
        .subscribe({
          next: (response) => {
            this.get(after);

            resolve(response);
          },
          error: (err: HttpErrorResponse) => {
            this.handleError(err);

            this.loading.hide();

            reject(err);
          },
        })
    })
  }

  saveFormData(after?: () => void, before?: () => void) {
    return new Promise((resolve, reject) => {
      this.loading.show();
      if (before) {
        before();
      }

      let method: "post" | "patch";
      let url: string;

      if (this.isNew) {
        method = "post";
        url = `${getEnv("API_URL")}${this.endpoint}`;
      } else {
        method = "patch";
        url = `${getEnv("API_URL")}${this.endpoint}/${(this.attributes as any)[this.pkName]}`;
      }

      const form = new FormData();

      for (const key in this.form.value) {
        form.append(key, this.form.get(key)?.value);
      }

      console.log(url);

      this.http[method](url, form, {
        params: this.params,
        headers: {
          "Authorization": `Bearer ${this.auth.bearer}`,
        },
      }).subscribe({
        next: (response) => {
          this.attributes = response as T;
          resolve(response);
        },
        error: (err: HttpErrorResponse) => {
          this.handleError(err);

          this.loading.hide();

          reject(err);
        },
        complete: () => {
          if (after) {
            after();
          }

          this.loading.hide();
        }
      });
    });
  }

  static new<T extends ModelI>(form?: FormGroup<any>, options?: {redirectTo?: string} ): Fetchervice<T> {
    const service = new Fetchervice<T>(
      inject(HttpClient),
      inject(AuthService),
      inject(Router),
      inject(RouteNamesService),
      inject(ToastService),
      inject(LoadingService),
      inject(StorageService),
    );

      service.redirectTo = options?.redirectTo;

    if (form) {
      service.setForm(form);
    }

    return service;
  }

  private handleError(err: HttpErrorResponse) {
    if (err.status == 401) {
      this.router.navigate([this.routeNames.path(this.auth.getLoginPath())]);
      this.toastService.show({ header: $localize`Session expired`, body: $localize`Please login again.`, classname: "bg-warning text-light", })
      this.auth.logout();
    } else if (err.status == 403) {
      this.router.navigate([this.routeNames.path(this.auth.getLoginPath())]);
      this.toastService.show({ header: $localize`Unauthorized`, body: $localize`Please login again.`, classname: "bg-warning text-light", });
      this.auth.logout();
    } else if (err.status == 0) {
      this.router.navigate([this.routeNames.path(this.auth.redirectTo())]);
      this.toastService.show({ header: $localize`An error has occurred`, body: $localize`Please try again or contact support.`, classname: "bg-warning text-light", });
    } else {
      this.toastService.show({ header: "ERROR", body: err.error?.message ?? "Error", classname: "bg-danger text-light", })
    }

    this.loading.hide();
  }

  get metadata(): EntityMetadata | undefined {
    return this.entity?.getMetadata();
  }

  private applyGetter(item: T): T {
    if (this.metadata) {
      this.metadata.attributes.forEach(attr => {
        const name = attr.name;

        if (attr.getter) {
          (item as any)[name] = attr.getter((item as any)[name], item)
        }
      })
    }

    return item;
  }

  post(url: string, body = {}, after?: (response: any) => void, before?: () => void) {
    return new Promise((resolve, reject) => {
      this.loading.show();
      if (before) {
        before();
      }

      this.http.post(url,
        this.form ? this.form.value : body, {
        params: this.params,
        headers: {
          "Authorization": `Bearer ${this.auth.bearer}`,
        },
      }).subscribe({
        next: (response) => {
          this.attributes = response as T;

          if (after) {
            after(response);
          }

          resolve(response);
        },
        error: (err: HttpErrorResponse) => {
          this.handleError(err);

          this.loading.hide();

          reject(err);
        },
        complete: () => {

          this.loading.hide();
        }
      });
    });
  }

  patch(url: string, body = {}, after?: (response: any) => void, before?: () => void) {
    return new Promise((resolve, reject) => {
      this.loading.show();
      if (before) {
        before();
      }

      this.http.patch(url,
        this.form ? this.form.value : body, {
        headers: {
          "Authorization": `Bearer ${this.auth.bearer}`,
        },
      }).subscribe({
        next: (response) => {
          this.attributes = response as T;

          if (after) {
            after(response);
          }

          resolve(response);
        },
        error: (err: HttpErrorResponse) => {
          this.handleError(err);

          this.loading.hide();

          reject(err);
        },
        complete: () => {

          this.loading.hide();
        }
      });
    });
  }

  update(id: number, body = {}, after?: (response: any) => void, before?: () => void) {
    const url = `${getEnv("API_URL")}${this.endpoint}/${id}`;
    this.patch(url, body, after, before);
  }
}
