import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
  ],
  template: `
    <header class="d-flex align-items-center justify-content-center">

    </header>
  `,
  styles: "header {min-height: 90px;}",
  host: { class: 'bg-white border-bottom border-light px-5 m-0' },
})
export class ClientHeader {
}