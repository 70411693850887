<!-- <app-header />

<main class="min-vh-100">
  <section class="container-fluid pb-5 pt-3 bg-white">
    <div class="container my-5">
      <hr>
      <h2 i18n>Booking Orders</h2>
      <hr>
      <ul class="nav nav-tabs small mb-3">
        <li class="nav-item">
          <a routerLink="/bookings/unpaid" routerLinkActive="active" class="nav-link">
            <span i18n>Unpaid</span>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/bookings/processing" routerLinkActive="active" class="nav-link">
            <span i18n>Processing</span>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/bookings/processed" routerLinkActive="active" class="nav-link">
            <span i18n>Processed</span>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/bookings/cancelled" routerLinkActive="active" class="nav-link">
            <span i18n>Cancelled</span>
          </a>
        </li>
      </ul>
      @if (model.items.length > 0) {
      <div class="row g-3">
        @for (item of model.items; track item.id) {
        <div class="col-sm-8">
          <div class="card shadow flex-grow-1">
            <div class="card-body">
              @if (isUnpaid) {
              <p class="small text-danger fst-italic">
                <span i18n>* We can only guarantee availability for</span> {{ item.timeRemaining$ | async | date:'mm:ss'
                }}
              </p>
              }
              <h3 class="card-title lh-1">
                @if (isUnpaid) {
                <span class="float-end small fs-5">
                  <span class="text-danger ps-4" role="button" (click)="delete(item.id)">
                    <i class="fa-solid fa-trash"></i>
                  </span>
                </span>
                }
                <a class="text-decoration-none text-dark"
                  [routerLink]="['/activity', item.booking?.activity?.id, item.booking?.activity?.slug]">
                  {{ item.booking?.activity?.title }}
                </a>
              </h3>
              <ul class="list-group list-group-horizontal small">
                <li class="list-group-item small py-1">
                  <i class="fa-regular fa-calendar"> </i>
                  <span class="ps-2">{{ item.booking?.date?.starts_at | date:'mediumDate' }}</span>
                </li>
                <li class="list-group-item small py-1">
                  <i class="fa-regular fa-clock"></i>
                  <span class="ps-2">{{ "0000-00-00T" + item.booking?.time?.starts_at | date:'mediumTime'}}</span>
                </li>
              </ul>
              <hr>
              <div class="table-responsive">
                <table class="table table-bordered">
                  <tbody>
                    @for (detail of item.details; track detail.id) {
                    <tr>
                      <td class="w-100">{{ detail.description }}</td>
                      <td>{{ detail.amount / detail.quantity | number:'2.2-2' }}</td>
                      <td>{{ detail.quantity | number:'2.0' }}</td>
                      <td class="text-end">{{ detail.amount | number:'1.2-2' }}</td>
                    </tr>
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="text-end fw-bold" colspan="3" i18n>TOTAL</td>
                      <td class="text-end text-nowrap">
                        <span class="fw-bold">Bs. {{ item.amount | number:'1.2-2' }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="py-0 text-end" colspan="4">
                        <span class="text-muted small fst-italic">Ref: {{ item.base_amount | number:'1.2-2' }}</span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card shadow">
            <div class="card-body">
              @if (isUnpaid) {
              <h3 class="card-title" i18n>Payment methods</h3>
              <span class="btn btn-primary w-100" role="button" (click)="payment(item.id)">Pago Inmediato</span>
              }
              @else {
              <h1>Poner algo aquí</h1>
              }
            </div>
          </div>
        </div>
        }
      </div>
      }
      @else {
      <div class="py-5 text-center">
        <i class="fa-solid fa-file-invoice text-muted mb-5" style="font-size: 160px;"></i>
        <h1 class="mb-3" i18n>You have no activities yet.</h1>
        <a class="btn btn-outline-secondary py-2 px-4 fs-5 rounded-pill" i18n routerLink="/search" i18n>
          Start exploring...
        </a>
      </div>
      }
    </div>
  </section>
</main>

<app-footer /> -->

<div class="container-fluid min-vh-100 p-0">
  <div class="d-flex">
    <navbar></navbar>

    <div class="bg-light flex-grow-1">
      <menu></menu>
      <main class="container-fluid p-5">
        <h1 class="fs-2" i18n>Booking Orders</h1>
        <!-- <p>Descripción</p> -->
        <ul class="nav nav-tabs small mb-3">
          <li class="nav-item">
            <a routerLink="/bookings/unpaid" routerLinkActive="active" class="nav-link">
              <span i18n>Unpaid</span>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/bookings/processing" routerLinkActive="active" class="nav-link">
              <span i18n>Processing</span>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/bookings/processed" routerLinkActive="active" class="nav-link">
              <span i18n>Processed</span>
            </a>
          </li>
          <!-- <li class="nav-item">
          <a routerLink="/bookings/cancelled" routerLinkActive="active" class="nav-link">
            <span i18n>Cancelled</span>
          </a>
        </li> -->
        </ul>
        <div class="border rounded-4 bg-white mt-5 p-5">
          <div class="row row-cols-1 g-3 ps-3">
            @if (model.items.length > 0) {
            @for (item of model.items; track item.id) {
              <div class="col-sm-8">
                <div class="card shadow flex-grow-1">
                  <div class="card-body">
                    @if (isUnpaid) {
                    <p class="small text-danger fst-italic">
                      <span i18n>* We can only guarantee availability for</span> {{ item.timeRemaining$ | async | date:'mm:ss'
                      }}
                    </p>
                    }
                    <h3 class="card-title lh-1">
                      @if (isUnpaid) {
                      <span class="float-end small fs-5">
                        <span class="text-danger ps-4" role="button" (click)="delete(item.id)">
                          <i class="fa-solid fa-trash"></i>
                        </span>
                      </span>
                      }
                      <a class="text-decoration-none text-dark"
                        [routerLink]="['/activity', item.booking?.activity?.id, item.booking?.activity?.slug]">
                        {{ item.booking?.activity?.title }}
                      </a>
                    </h3>
                    <ul class="list-group list-group-horizontal small">
                      <li class="list-group-item small py-1">
                        <i class="fa-regular fa-calendar"> </i>
                        <span class="ps-2">{{ item.booking?.date?.starts_at | date:'mediumDate' }}</span>
                      </li>
                      <li class="list-group-item small py-1">
                        <i class="fa-regular fa-clock"></i>
                        <span class="ps-2">{{ "0000-00-00T" + item.booking?.time?.starts_at | date:'mediumTime'}}</span>
                      </li>
                    </ul>
                    <hr>
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <tbody>
                          @for (detail of item.details; track detail.id) {
                          <tr>
                            <td class="w-100">{{ detail.description }}</td>
                            <td>{{ detail.amount / detail.quantity | number:'2.2-2' }}</td>
                            <td>{{ detail.quantity | number:'2.0' }}</td>
                            <td class="text-end">{{ detail.amount | number:'1.2-2' }}</td>
                          </tr>
                          }
                        </tbody>
                        <tfoot>
                          <tr>
                            <td class="text-end fw-bold" colspan="3" i18n>TOTAL</td>
                            <td class="text-end text-nowrap">
                              <span class="fw-bold">Bs. {{ item.amount | number:'1.2-2' }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="py-0 text-end" colspan="4">
                              <span class="text-muted small fst-italic">Ref: {{ item.base_amount | number:'1.2-2' }}</span>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="card shadow">
                  <div class="card-body">
                    @if (isUnpaid) {
                    <h3 class="card-title" i18n>Payment methods</h3>
                    <span class="btn btn-primary w-100" role="button" (click)="payment(item.id)">Pago Inmediato</span>
                    }
                    @else {
                    <h1>Poner algo aquí</h1>
                    }
                  </div>
                </div>
              </div>
            }
            }
            @else {
              <card square *ngFor="let number of [1,2]"></card>
            }
          </div>
        </div>
      </main>
      <footer></footer>
    </div>
  </div>
</div>