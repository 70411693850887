import { Component, Input } from '@angular/core';
import { CommonModule, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'rating',
    standalone: true,
    imports: [NgTemplateOutlet, CommonModule],
    template: `
    <div class="d-flex">
        <div><i class="fa-solid fa-star text-main"></i></div>
        <div><i class="fa-solid fa-star text-main" [ngClass]="rating > 1 ? 'text-main' : 'text-main-subtle'"></i></div>
        <div><i class="fa-solid fa-star text-main" [ngClass]="rating > 2 ? 'text-main' : 'text-main-subtle'"></i></div>
        <div><i class="fa-solid fa-star text-main" [ngClass]="rating > 3 ? 'text-main' : 'text-main-subtle'"></i></div>
        <div><i class="fa-solid fa-star text-main" [ngClass]="rating > 4 ? 'text-main' : 'text-main-subtle'"></i></div>
    </div>
	`,
})
export class Rating {
    @Input() rating: number = 0;
}