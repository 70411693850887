import { Component, OnInit } from '@angular/core';
import {
  CommonModule, DatePipe,
  NgOptimizedImage,
} from '@angular/common'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../components/public/header/component';
import { FooterComponent } from '../../../components/public/footer/component';
import { MetaService } from '../../../services/meta.service';
import { Activity } from '../../../models/activity';
import { Fetchervice } from '../../../services/fetch.service';
import { CacheService } from '../../../services/cache.service';
import { ActivityCategory } from '../../../models/activity_category';
import { log } from 'node:console';
import { Validate } from '../../../services/validator';
import { HttpClient } from '@angular/common/http';
import { getEnv } from '../../../services/config.service';
import { Location } from '../../../models/location';
import { ActivityAttribute } from '../../../models/activity_attribute';
import { RectangleArticle } from '../../../components/public/article/rectangle';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HeaderComponent,
    FooterComponent,
    NgOptimizedImage,
    RouterModule,
    RectangleArticle,
  ],
  providers: [
    DatePipe,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class SearchComponent<T extends Activity> implements OnInit {
  model: Fetchervice<T>;
  categories = Fetchervice.new<ActivityCategory>();
  locations = Fetchervice.new<Location>();
  attributes = Fetchervice.new<ActivityAttribute>();
  fetch: Fetchervice<any>;

  page: number = 1;
  items: T[] = [];
  filters: {
    categories: number[];
    attribute: number[];
  } = {
    categories: [],
    attribute: [],
  }

  params = new FormGroup({
    starts_at: new FormControl(),
    origin_id: new FormControl(''),
    destination_id: new FormControl(''),
    category_id: new FormControl(Array<number>()),
    attribute_id: new FormControl(Array<number>()),
    base_price_gt: new FormControl('', Validate.min(0)),
    base_price_lt: new FormControl(''),
  });

  constructor(
    private meta: MetaService,
    private route: ActivatedRoute,
    private cache: CacheService,
  ) {
    this.meta.title($localize`Search`);
    this.model = Fetchervice.new<T>();
    this.fetch = Fetchervice.new<any>();
  }

  load() {
    this.model
      .paginate(this.page, 20)
      .where("status", "1")
      .get(() => {
        for (const item of this.model.items) {
          this.items.push(item);
        }
      });

    this.page++;
  }

  setParams() {
    for (const key of Object.keys(this.params.value)) {
      const value = (this.params.value as any)[key];
      if (value) {
        this.model.params[key] = value;
      } else {
        delete this.model.params[key];
      }
    }

    this.emptyItems();

    this.load();
  }

  emptyItems() {
    this.items = [];
    this.page = 1;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.emptyItems();

      // const search = params["s"];
      const category_id = params["cat"];
      const location_id = params["location"];
      const starts_at = params["date"];

      // if (search) {
      //   this.model.where("search", search);
      // }

      if (category_id) {
        this.model.where("category_id", category_id);
        this.params.get("category_id")?.setValue([parseInt(category_id)]);
      }

      if (location_id) {
        this.model.where("destination_id", location_id);
        this.params.get("destination_id")?.setValue(location_id);
      }

      if (starts_at) {
        this.model.where("starts_at" as any, starts_at);
      }

      this.model
        .from(Activity)
        .relations(["images", "category", "destination", "tags"])
        .orderBy("id", "asc");

      this.load();
    });

    const keyCat = "categories";

    if (this.cache.has(keyCat)) {
      this.categories.items = this.cache.get(keyCat);
    } else {
      this.categories
        .from(ActivityCategory)
        .where("status", "1")
        .cache(true)
        .get(() => {
          this.cache.set(keyCat, this.categories.items);
        });
    }

    const keyCit = "locations";

    if (this.cache.has(keyCit)) {
      this.locations.items = this.cache.get(keyCit);
    } else {
      this.locations
        .from(Location)
        .where("status", "1")
        .cache(true)
        .get(() => {
          this.cache.set(keyCit, this.locations.items);
        });
    }

    const keyAttr = "attributes";

    if (this.cache.has(keyAttr)) {
      this.attributes.items = this.cache.get(keyAttr);
    } else {
      this.attributes
        .from(ActivityAttribute)
        .where("status", "1")
        .cache(true)
        .get(() => {
          this.cache.set(keyAttr, this.attributes.items);
        });
    }
  }

  onCategoryChecked(event: Event) {
    const { value, checked } = event.target as any
    if (checked) {
      this.filters.categories.push(value);
    } else {
      this.filters.categories = this.filters.categories.filter(id => id != value)
    }

    this.params.get("category_id")?.setValue(this.filters.categories);
    this.setParams();
  }

  isCategoryChecked(id: number) {    
    return this.params.get("category_id")?.value?.includes(id);
  }

  onAttributeChecked(event: Event) {
    const { value, checked } = event.target as any
    if (checked) {
      this.filters.attribute.push(value);
    } else {
      this.filters.attribute = this.filters.attribute.filter(id => id != value)
    }

    this.params.get("attribute_id")?.setValue(this.filters.attribute);
    this.setParams();
  }

  bookmark(activity: Activity) {
    activity.bookmarked = true;
    
    this.fetch.post(`${getEnv("API_URL")}/api/v1/activity/${activity.id}/bookmark`)
  }

  unbookmark(activity: Activity) {
    activity.bookmarked = false;
    
    this.fetch.post(`${getEnv("API_URL")}/api/v1/activity/${activity.id}/unbookmark`)
  }

  startsAtToday() {
    const now = new Date();
    this.params.get("starts_at")?.setValue(`${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`);
    
    this.setParams();
  }

  startsAtTomorrow() {
    const now = new Date();
    now.setDate(now.getDate() + 1);
    this.params.get("starts_at")?.setValue(`${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`);
    
    this.setParams();
  }

  startAtDateChange(event: any) {
    this.params.get("starts_at")?.setValue(event.target.value);
    this.setParams();
  }
}
