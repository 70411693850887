<!-- <app-header />

<main class="min-vh-100">
  <section class="container-fluid pb-5 pt-3">

    <div class="container my-5">
      <hr>
      <h2 i18n>Shopping Cart</h2>
      <hr>
      @if (model.items.length > 0) {
      <div class="row g-5">
        <div class="col-sm-8 bg-white py-3">
          <p class="small text-danger fst-italic" i18n>
            * Remember: we can only guarantee availability until 12 midnight
          </p>
          <hr>
          <div class="row g-3">
            @for (item of model.items; track item.id) {
            <article class="col-12">
              <div class="d-flex flex-row">
                <div class="flex-shrink-0">
                  <img
                  [src]="item?.activity?.images[0]?.path"
                  [alt]="item.activity?.title"
                  width="270"
                  height="180"
                >
                </div>
                <div class="card shadow flex-grow-1">
                  <div class="card-body">
                    <h3 class="card-title lh-1">
                      <span class="float-end small fs-5">
                        <span class="text-info ps-4" role="button">
                          <i class="fa-solid fa-pencil"></i>
                        </span>
                        <span class="text-danger ps-4" role="button" (click)="delete(item.id)">
                          <i class="fa-solid fa-trash"></i>
                        </span>
                      </span>
                      <a class="text-decoration-none text-dark"
                        [routerLink]="['/activity', item.activity?.id, item.activity?.slug]">
                        {{ item.activity?.title }}
                      </a>
                    </h3>
                    <p class="small">{{ item.activity?.summary }}</p>
                    <ul class="list-group list-group-horizontal small">
                      <li class="list-group-item small py-1">
                        <i class="fa-regular fa-calendar"> </i>
                        <span class="ps-2">{{ item.date?.starts_at | date:'mediumDate' }}</span>
                      </li>
                      <li class="list-group-item small py-1">
                        <i class="fa-regular fa-clock"></i>
                        <span class="ps-2">{{ "0000-00-00T" + item.time?.starts_at | date:'mediumTime'}}</span>
                      </li>
                    </ul>
                    <hr>
                    <div class="table-responsive">
                      <table class="table table-bordered">
                        <tbody>
                          @for (detail of item.details; track detail.id) {
                          <tr>
                            <td class="w-100">{{ detail.price?.title }}</td>
                            <td class="text-end">{{ detail.price?.price | number:'2.2-2' }}</td>
                            <td>{{ detail.quantity | number:'2.0' }}</td>
                            <td class="text-end">{{ detail.quantity * (detail.price?.price ?? 0) | number:'2.2-2' }}</td>
                          </tr>
                          }
                        </tbody>
                        <tfoot>
                          <tr class="fw-bold">
                            <td class="text-end" colspan="3" i18n>TOTAL</td>
                            <td class="text-end">{{ getActivityTotal(item.details) | number:'2.2-2' }}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            }
            @empty {
            <div class="col-12">
              <div class="card rounded-0">
                <div class="card-body">
                  <h3 class="card-title" i18n>You don't have reservations yet</h3>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card shadow mb-3">
            <div class="card-body">
              <h4 class="card-title" i18n>Order Summary</h4>
              <div class="row py-3">
                <div class="col-6 pt-3 text-end">
                  <span class="fs-5" i18sn>Total price</span>
                </div>
                <div class="col-6 pt-3 text-start">
                  <i class="fa-solid fa-dollar-sign"></i>
                  <span class="fs-5 ps-1">{{ getTotal() | number:'2.2-2' }}</span>
                </div>
                <div class="col">
                  <p class="text-muted small fst-italic text-end pe-5">All taxes and fees included</p>
                </div>
              </div>
            </div>
            <div class="card-footer d-flex justify-content-evenly py-5">
              <a class="btn btn-outline-secondary py-2 px-4 rounded-pill" i18n routerLink="/search">
                Book more...
              </a>
              <button class="btn btn-success py-2 px-4 rounded-pill" (click)="placeOrder()" i18n>
                Continue
              </button>
            </div>
          </div>
          <div class="card shadow">
            <div class="card-body">
              <p class="fw-bold fs-5">Why book with us?</p>
              <ul style="list-style-type: none;">
                <li>
                  <i class="fa-solid fa-lock text-muted"></i>
                  <span class="ps-2 fst-italic">Secure payment</span>
                </li>
                <li>
                  <i class="fa-solid fa-clock text-muted"></i>
                  <span class="ps-2 fst-italic">Online payment</span>
                </li>
                <li>
                  <i class="fa-solid fa-circle-check text-muted"></i>
                  <span class="ps-2 fst-italic">No hidden costs</span>
                </li>
                <li>
                  <i class="fa-solid fa-comment text-muted"></i>
                  <span class="ps-2 fst-italic">24/7 customer support</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      }
      @else {
      <div class="py-5 text-center">
        <i class="fa-solid fa-cart-shopping text-muted mb-5" style="font-size: 160px;"></i>
        <h1 class="mb-3" i18n>You have no activities yet.</h1>
        <a class="btn btn-outline-secondary py-2 px-4 fs-5 rounded-pill" i18n routerLink="/search" i18n>
          Start exploring...
        </a>
      </div>
      }
    </div>
  </section>
</main>

<app-footer /> -->

<div class="container-fluid min-vh-100 p-0">
  <div class="d-flex">
    <navbar></navbar>

    <div class="bg-light flex-grow-1">
      <menu></menu>
      <main class="container-fluid p-5">
        <h1 class="fs-2">Shopping Cart</h1>
        <!-- <p>Descripción</p> -->
        <div class="border rounded-4 bg-white mt-5 p-5">
          @if (model.items.length > 0) {
          @for (item of model.items; track item.id) {
          <card rectangle [item]="item.activity"></card>
          <article class="mt-3">
            <!-- <div class="d-flex flex-row"> -->
            <!-- <h3 class="card-title lh-1">
                        <span class="float-end small fs-5">
                          <span class="text-info ps-4" role="button">
                            <i class="fa-solid fa-pencil"></i>
                          </span>
                          <span class="text-danger ps-4" role="button" (click)="delete(item.id)">
                            <i class="fa-solid fa-trash"></i>
                          </span>
                        </span>
                        <a class="text-decoration-none text-dark"
                          [routerLink]="['/activity', item.activity?.id, item.activity?.slug]">
                          {{ item.activity?.title }}
                        </a>
                      </h3> -->
            <!-- <p class="small">{{ item.activity?.summary }}</p> -->
            <ul class="list-group list-group-horizontal small">
              <li class="list-group-item small py-1">
                <i class="fa-regular fa-calendar"> </i>
                <span class="ps-2">{{ item.date?.starts_at | date:'mediumDate' }}</span>
              </li>
              <li class="list-group-item small py-1">
                <i class="fa-regular fa-clock"></i>
                <span class="ps-2">{{ "0000-00-00T" + item.time?.starts_at | date:'mediumTime'}}</span>
              </li>
            </ul>
            <div class="table-responsive w-50 border-end pe-3">
              <table class="table table-borderless">
                <tbody>
                  @for (detail of item.details; track detail.id) {
                  <tr>
                    <td class="w-100 text-end">{{ detail.price?.title }}</td>
                    <td class="text-end">{{ detail.price?.price | number:'2.2-2' }}</td>
                    <td>{{ detail.quantity | number:'2.0' }}</td>
                    <td class="text-end">{{ detail.quantity * (detail.price?.price ?? 0) | number:'2.2-2' }}</td>
                  </tr>
                  }
                </tbody>
                <tfoot>
                  <tr class="fw-bold">
                    <td></td>
                    <td class="border-top"></td>
                    <td class="text-end border-top" i18n>TOTAL</td>
                    <td class="text-end border-top">{{ getActivityTotal(item.details) | number:'2.2-2' }}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <!-- </div> -->
          </article>
          }
          }
        </div>
      </main>
      <footer></footer>
    </div>
  </div>
</div>