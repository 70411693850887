import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'footer',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
  ],
  template: `
    <p class="text-center">
      © Copyright Xplora {{ date | date:"YYYY" }}
    </p>
  `,
})
export class ClientFooter {
  date = new Date();
}