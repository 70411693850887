<app-header color="text-main" />

<main class="min-vh-100 py-5">
  <section class="container-fluid">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-7 col-md-9">
          <div class="text-center mb-5 mb-md-3">
            <h1 class="fs-3 fw-bolder" i18n>Login</h1>
            <div class="fw-medium mt-4" i18n>We're glad to see you again!</div>
            <div class="mt-3 mb-5 fw-light small">
              <span i18n>Don't have an account?</span>
              <span>&nbsp;</span>
              <a routerLink="/register" class="text-main text-decoration-none" i18n>Register here</a>
            </div>
          </div>
          <form class="form needs-validation border rounded-4 p-5" novalidate [formGroup]="form" (ngSubmit)="onSubmit()">

            <div class="form-floating mb-3">
              <input type="text" id="email" class="form-control"
                [ngClass]="model.isInvalid('email') ? 'is-invalid' : ''" formControlName="email" required
                i18n-placeholder placeholder="Email" autocomplete="email">
              <label for="email" i18n>Email</label>

              <div *ngIf="model.isInvalid('email')" id="email-feedback" class="invalid-feedback">
                <div *ngIf="email.errors?.['required']" i18n>Email field is required</div>
                <div *ngIf="email.errors?.['minlength']" i18n>Email must be at least 3 characters long.</div>
                <div *ngIf="email.errors?.['email']" i18n>Most be a valid email.</div>
              </div>
            </div>

            <div class="form-floating mb-3">
              <input type="password" id="password" class="form-control"
                [ngClass]="model.isInvalid('password') ? 'is-invalid' : ''" formControlName="password" required
                i18n-placeholder placeholder="Password" autocomplete="current-password">
              <label for="password" i18n>Password</label>

              <div *ngIf="model.isInvalid('password')" id="password-feedback" class="invalid-feedback">
                <div *ngIf="password.errors?.['required']" i18n>Password is required</div>
                <div *ngIf="password.errors?.['minlength']" i18n>Password must be at least 8 characters long.</div>
              </div>
            </div>

            <div class="row y-ga-10 justify-content-between items-center pt-3">
              <!-- <div class="col-auto">
                <div class="d-flex items-center">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      Remember me
                    </label>
                  </div>
                </div>
              </div> -->
              <div class="col text-end">
                <a routerLink="/forgot" class="text-main text-decoration-none text-end" i18n>Forgot password?</a>
              </div>
            </div>
            <button type="submit" class="btn btn-main text-white col-12 mt-3 py-3 rounded-4" [disabled]="!form.valid || loading.isLoading" i18n>
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</main>

<app-footer />