<app-header />

<main class="container-fluid my-5 min-vh-100">
  <div class="row g-0">
    <aside class="col-sm-3 border">
      <form class="form w-100" [formGroup]="params">
        <div class="accordion accordion-flush p-2" id="accordionExample">
          <div class="accordion-item border-0">
            <h2 class="accordion-header">
              <button class="accordion-button py-2 bg-light" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Disponibilidad
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div class="accordion-body px-0 pt-1 pb-3">
                <div class="input-group input-group-sm">
                  <button class="btn btn-outline-secondary col" type="button" (click)="startsAtToday()">Hoy</button>
                  <button class="btn btn-outline-secondary col" type="button"
                    (click)="startsAtTomorrow()">Mañana</button>
                  <input class="form-control col" type="date" name="starts_at" id="starts_at"
                    (change)="startAtDateChange($event)">
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <h2 class="accordion-header">
              <button class="accordion-button py-2 bg-light" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Ubicación
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div class="accordion-body px-0 pt-1 pb-3">
                <div class="input-group input-group-sm">
                  <label class="input-group-text col rounded-bottom-0 bg-white" for="">Origen</label>
                  <label class="input-group-text col rounded-bottom-0 bg-white" for="">Destino</label>
                </div>
                <div class="input-group input-group-sm">
                  <select id="origin_id" name="origin_id" class="form-select rounded-top-0 border-top-0"
                    formControlName="origin_id" (change)="setParams()">
                    <option value="">Seleccione</option>
                    @for (location of locations.items; track location.id) {
                    <option [value]="location.id">{{ location.name }}</option>
                    }
                  </select>
                  <select id="destination_id" name="destination_id"
                    class="form-select rounded-top-0 border-top-0" formControlName="destination_id"
                    (change)="setParams()">
                    <option value="">Seleccione</option>
                    @for (location of locations.items; track location.id) {
                    <option [value]="location.id">{{ location.name }}</option>
                    }
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <h2 class="accordion-header">
              <button class="accordion-button py-2 bg-light" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Categorías
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div class="accordion-body px-0 pt-1 pb-3">
                @for (category of categories.items; track category.id) {
                <div class="form-check border-bottom">
                  <input class="form-check-input" type="checkbox" [value]="category.id" id="category_id"
                    (change)="onCategoryChecked($event)" [checked]="isCategoryChecked(category.id)">
                  <label class="form-check-label" for="category_id">
                    {{ category.name }}
                  </label>
                </div>
                }
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <h2 class="accordion-header">
              <button class="accordion-button py-2 bg-light" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Detalles
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div class="accordion-body px-0 pt-1 pb-3">
                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                  <div class="accordion-body px-0 pt-1 pb-3">
                    @for (attribute of attributes.items; track attribute.id) {
                    <div class="form-check border-bottom">
                      <input class="form-check-input" type="checkbox" [value]="attribute.id" id="attribute_id"
                        (change)="onAttributeChecked($event)">
                      <label class="form-check-label" for="attribute_id">
                        {{ attribute.name }} - {{ attribute.description }}
                      </label>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item border-0">
            <h2 class="accordion-header">
              <button class="accordion-button py-2 bg-light" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Precio
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div class="accordion-body px-0 pt-1 pb-3">
                <div class="input-group input-group-sm">
                  <input id="base_price_gt" name="base_price_gt" type="number" class="form-control" placeholder="Min"
                    formControlName="base_price_gt" (change)="setParams()" min="0">
                  <input id="base_price_lt" name="base_price_lt" type="number" class="form-control" placeholder="Max"
                    formControlName="base_price_lt" (change)="setParams()" min="0">
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </aside>
    <section class="col-sm-9">
      <h1 class="ps-3">Búsqueda</h1>
      <div class="row row-cols-1 g-3 ps-3">
        @if (items.length > 0) {
        @for (item of items; track item.id) {
          <article rectangle [item]="item"></article>
        }
        @if (model.pagination.current < model.pagination.last) {
          <div class="col-sm-12 text-center">
            <span class="btn btn-sm btn-outline-secondary" role="button" (click)="load()" i18n>
              Load more...
            </span>
          </div>
        }
        }
        @else {
          <article rectangle *ngFor="let number of [1,2,3,4]"></article>
        }
  </div>
  </section>
  </div>
</main>

<app-footer />