import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { getEnv } from './config.service';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: "root"
})
export class MetaService {
    private baseURL = getEnv("APP_URL");
    private baseTitle = getEnv("APP_NAME");
    private baseDescription = getEnv("APP_DESCRIPTION");
    private canonicalLink: HTMLLinkElement;

    constructor(
        private titleService: Title,
        private meta: Meta,
        private router: Router,
        @Inject( DOCUMENT ) private document: Document
    ) {
        const fullURL = `${this.baseURL}${this.router.url}`;
        this.canonicalLink = this.document.getElementById("canonical") as HTMLLinkElement;

        this.canonicalLink.setAttribute( 'rel', 'canonical' );
        this.canonicalLink.setAttribute( 'href', fullURL );

        this.meta.updateTag({ name: "og:url", content:  fullURL});
        this.meta.updateTag({ name: "og:type", content: "website" });
        this.meta.updateTag({ name: "og:title", content: this.baseTitle });
        this.meta.updateTag({ name: "og:description", content: this.baseDescription })
        this.meta.updateTag({ name: "og:image", content: "" });

        this.meta.updateTag({ name: "twitter:card", content: "sumary" });
        this.meta.updateTag({ name: "twitter:title", content: this.baseTitle });
        this.meta.updateTag({ name: "twitter:description", content: this.baseDescription });
        this.meta.updateTag({ name: "twitter:image", content: "" });
    }

    public title(str: string): void {
        this.titleService.setTitle(`${this.baseTitle} | ${str}`);
        this.meta.updateTag({ name: "og:title", content: str });
        this.meta.updateTag({ name: "twitter:title", content: str });
    }

    public description(str: string): void {
        this.meta.updateTag({ name: "description", content: str });
        this.meta.updateTag({ name: "og:description", content: str });
        this.meta.updateTag({ name: "twitter:description", content: str });
    }

    public image(str: string): void {
        this.meta.updateTag({ name: "twitter:image", content: str });
        this.meta.updateTag({ name: "og:image", content: str });

    }

    public setArticle(): void {
        this.meta.updateTag({ name: "og:type", content: "article" });
    }
}