import * as i0 from '@angular/core';
import { Injectable, Inject, EventEmitter, Component, Input, Output, ViewChild, HostListener, Directive, NgModule } from '@angular/core';
import * as i2$1 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i2 from '@angular/forms';
import { NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
const _c0 = ["input"];
const _c1 = ["window"];
function EditorInputComponent_div_1_label_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.button == null ? null : ctx_r1.button.label);
  }
}
function EditorInputComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4, 0);
    i0.ɵɵlistener("keyup.escape", function EditorInputComponent_div_1_Template_div_keyup_escape_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.closeInputWindow());
    });
    i0.ɵɵtemplate(2, EditorInputComponent_div_1_label_2_Template, 2, 1, "label", 5);
    i0.ɵɵelementStart(3, "div")(4, "input", 6, 1);
    i0.ɵɵtwoWayListener("ngModelChange", function EditorInputComponent_div_1_Template_input_ngModelChange_4_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      i0.ɵɵtwoWayBindingSet(ctx_r1.value, $event) || (ctx_r1.value = $event);
      return i0.ɵɵresetView($event);
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "button", 7);
    i0.ɵɵlistener("click", function EditorInputComponent_div_1_Template_button_click_6_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onCommand());
    });
    i0.ɵɵtext(7, "Confirm");
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵstyleProp("margin-left", ctx_r1.margin, "px")("opacity", ctx_r1.opacity);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r1.button == null ? null : ctx_r1.button.label);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("title", ctx_r1.button == null ? null : ctx_r1.button.label);
    i0.ɵɵtwoWayProperty("ngModel", ctx_r1.value);
  }
}
function EditorSelectComponent_option_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 2);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const i_r1 = ctx.$implicit;
    i0.ɵɵproperty("value", i_r1.value);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i_r1.label);
  }
}
const _c2 = ["select"];
function EditorDropdownComponent_option_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const i_r3 = ctx.$implicit;
    i0.ɵɵproperty("value", i_r3.label);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i_r3.label);
  }
}
const _c3 = ["contentEditable"];
function EditorComponent_ng_container_2_st_separator_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "st-separator");
  }
}
function EditorComponent_ng_container_2_st_button_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "st-button", 10);
    i0.ɵɵlistener("command", function EditorComponent_ng_container_2_st_button_2_Template_st_button_command_0_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.execCommand($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r4 = i0.ɵɵnextContext().$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("button", item_r4)("state", ctx_r2.queryCommandState[item_r4.command]);
  }
}
function EditorComponent_ng_container_2_st_select_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "st-select", 10);
    i0.ɵɵlistener("command", function EditorComponent_ng_container_2_st_select_3_Template_st_select_command_0_listener($event) {
      i0.ɵɵrestoreView(_r5);
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.execCommand($event.command, $event.value));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r4 = i0.ɵɵnextContext().$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("button", item_r4)("state", ctx_r2.queryCommandState[item_r4.command]);
  }
}
function EditorComponent_ng_container_2_st_color_picker_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "st-color-picker", 10);
    i0.ɵɵlistener("command", function EditorComponent_ng_container_2_st_color_picker_4_Template_st_color_picker_command_0_listener($event) {
      i0.ɵɵrestoreView(_r6);
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.execCommand($event.command, $event.value));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r4 = i0.ɵɵnextContext().$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("button", item_r4)("state", ctx_r2.queryCommandState[item_r4.command]);
  }
}
function EditorComponent_ng_container_2_st_input_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "st-input", 11);
    i0.ɵɵlistener("command", function EditorComponent_ng_container_2_st_input_5_Template_st_input_command_0_listener($event) {
      i0.ɵɵrestoreView(_r7);
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.execCommand($event.command, $event.value));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r4 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("button", item_r4);
  }
}
function EditorComponent_ng_container_2_st_dropdown_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r8 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "st-dropdown", 11);
    i0.ɵɵlistener("command", function EditorComponent_ng_container_2_st_dropdown_6_Template_st_dropdown_command_0_listener($event) {
      i0.ɵɵrestoreView(_r8);
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.execCommand($event.command, $event.value));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r4 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("button", item_r4);
  }
}
function EditorComponent_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0, 5);
    i0.ɵɵtemplate(1, EditorComponent_ng_container_2_st_separator_1_Template, 1, 0, "st-separator", 6)(2, EditorComponent_ng_container_2_st_button_2_Template, 1, 2, "st-button", 7)(3, EditorComponent_ng_container_2_st_select_3_Template, 1, 2, "st-select", 8)(4, EditorComponent_ng_container_2_st_color_picker_4_Template, 1, 2, "st-color-picker", 8)(5, EditorComponent_ng_container_2_st_input_5_Template, 1, 1, "st-input", 9)(6, EditorComponent_ng_container_2_st_dropdown_6_Template, 1, 1, "st-dropdown", 9);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const item_r4 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngSwitch", item_r4.type);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", ctx_r2.toolbarItemType.Separator);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", item_r4.type === ctx_r2.toolbarItemType.Button);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", ctx_r2.toolbarItemType.Select);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", ctx_r2.toolbarItemType.Color);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", ctx_r2.toolbarItemType.Input);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngSwitchCase", ctx_r2.toolbarItemType.Dropdown);
  }
}
var ToolbarItemType;
(function (ToolbarItemType) {
  ToolbarItemType[ToolbarItemType["Separator"] = 0] = "Separator";
  ToolbarItemType[ToolbarItemType["Button"] = 1] = "Button";
  ToolbarItemType[ToolbarItemType["Select"] = 2] = "Select";
  ToolbarItemType[ToolbarItemType["Color"] = 3] = "Color";
  ToolbarItemType[ToolbarItemType["Input"] = 4] = "Input";
  ToolbarItemType[ToolbarItemType["Dropdown"] = 5] = "Dropdown";
})(ToolbarItemType || (ToolbarItemType = {}));
const UNDO_BUTTON = {
  type: ToolbarItemType.Button,
  command: "undo" /* ExecCommand.undo */,
  icon: 'fas fa-undo',
  title: 'undo'
};
const REDO_BUTTON = {
  type: ToolbarItemType.Button,
  command: "redo" /* ExecCommand.redo */,
  icon: 'fas fa-redo',
  title: 'redo'
};
const REMOVE_FORMAT_BUTTON = {
  type: ToolbarItemType.Button,
  command: "removeFormat" /* ExecCommand.removeFormat */,
  icon: 'fas fa-remove-format',
  title: 'remove format'
};
const SEPARATOR = {
  type: ToolbarItemType.Separator
};
const BOLD_BUTTON = {
  type: ToolbarItemType.Button,
  command: "bold" /* ExecCommand.bold */,
  icon: 'fas fa-bold',
  title: 'bold'
};
const ITALIC_BUTTON = {
  type: ToolbarItemType.Button,
  command: "italic" /* ExecCommand.italic */,
  icon: 'fas fa-italic',
  title: 'italic'
};
const UNDERLINE_BUTTON = {
  type: ToolbarItemType.Button,
  command: "underline" /* ExecCommand.underline */,
  icon: 'fas fa-underline',
  title: 'underline'
};
const STRIKE_THROUGH_BUTTON = {
  type: ToolbarItemType.Button,
  command: "strikeThrough" /* ExecCommand.strikeThrough */,
  icon: 'fas fa-strikethrough',
  title: 'strikethrough'
};
const JUSTIFY_LEFT_BUTTON = {
  type: ToolbarItemType.Button,
  command: "justifyLeft" /* ExecCommand.justifyLeft */,
  icon: 'fas fa-align-left',
  title: 'align left'
};
const JUSTIFY_CENTER_BUTTON = {
  type: ToolbarItemType.Button,
  command: "justifyCenter" /* ExecCommand.justifyCenter */,
  icon: 'fas fa-align-center',
  title: 'align center'
};
const JUSTIFY_RIGHT_BUTTON = {
  type: ToolbarItemType.Button,
  command: "justifyRight" /* ExecCommand.justifyRight */,
  icon: 'fas fa-align-right',
  title: 'align right'
};
const JUSTIFY_FULL_BUTTON = {
  type: ToolbarItemType.Button,
  command: "justifyFull" /* ExecCommand.justifyFull */,
  icon: 'fas fa-align-justify',
  title: 'align justify'
};
const ORDERED_LIST_BUTTON = {
  type: ToolbarItemType.Button,
  command: "insertOrderedList" /* ExecCommand.insertOrderedList */,
  icon: 'fas fa-list-ol',
  title: 'ordered list'
};
const UNORDERED_LIST_BUTTON = {
  type: ToolbarItemType.Button,
  command: "insertUnorderedList" /* ExecCommand.insertUnorderedList */,
  icon: 'fas fa-list-ul',
  title: 'unordered list'
};
const INDENT_BUTTON = {
  type: ToolbarItemType.Button,
  command: "indent" /* ExecCommand.indent */,
  icon: 'fas fa-indent',
  title: 'indent'
};
const OUTDENT_BUTTON = {
  type: ToolbarItemType.Button,
  command: "outdent" /* ExecCommand.outdent */,
  icon: 'fas fa-outdent',
  title: 'outdent'
};
const SUBSCRIPT_BUTTON = {
  type: ToolbarItemType.Button,
  command: "subscript" /* ExecCommand.subscript */,
  icon: 'fas fa-subscript',
  title: 'subscript'
};
const SUPERSCRIPT_BUTTON = {
  type: ToolbarItemType.Button,
  command: "superscript" /* ExecCommand.superscript */,
  icon: 'fas fa-superscript',
  title: 'superscript'
};
const FONT_SIZE_SELECT = {
  type: ToolbarItemType.Select,
  command: "fontSize" /* ExecCommand.fontSize */,
  title: 'font size',
  items: [{
    value: 1,
    label: '1'
  }, {
    value: 2,
    label: '2'
  }, {
    value: 3,
    label: '3'
  }, {
    value: 4,
    label: '4'
  }, {
    value: 5,
    label: '5'
  }, {
    value: 6,
    label: '6'
  }, {
    value: 7,
    label: '7'
  }]
};
const LINK_INPUT = {
  type: ToolbarItemType.Input,
  command: "createLink" /* ExecCommand.createLink */,
  icon: 'fas fa-link',
  text: 'Create link',
  title: 'create link',
  label: 'Create link'
};
const UNLINK_BUTTON = {
  type: ToolbarItemType.Button,
  command: "unlink" /* ExecCommand.unlink */,
  icon: 'fas fa-unlink',
  title: 'unlink'
};
const FORE_COLOR = {
  type: ToolbarItemType.Color,
  command: "foreColor" /* ExecCommand.foreColor */,
  icon: 'fas fa-palette',
  title: 'font color'
};
const IMAGE_INPUT = {
  type: ToolbarItemType.Input,
  command: "insertImage" /* ExecCommand.insertImage */,
  icon: 'fas fa-image',
  text: 'Add image',
  title: 'add image',
  label: 'Create image from link'
};
const CUSTOM = {
  type: ToolbarItemType.Dropdown,
  label: 'Custom',
  title: 'test custom',
  items: [{
    command: "insertHTML" /* ExecCommand.insertHTML */,
    value: '<b>This is bold<b>',
    label: 'Custom bold text'
  }, {
    command: "italic" /* ExecCommand.italic */,
    value: null,
    label: 'Make Italic'
  }]
};
const ST_BUTTONS = [UNDO_BUTTON, REDO_BUTTON, REMOVE_FORMAT_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, UNDERLINE_BUTTON, STRIKE_THROUGH_BUTTON, SEPARATOR, JUSTIFY_LEFT_BUTTON, JUSTIFY_CENTER_BUTTON, JUSTIFY_RIGHT_BUTTON, JUSTIFY_FULL_BUTTON, SEPARATOR, ORDERED_LIST_BUTTON, UNORDERED_LIST_BUTTON, SEPARATOR, INDENT_BUTTON, OUTDENT_BUTTON, SEPARATOR, SUBSCRIPT_BUTTON, SUPERSCRIPT_BUTTON, SEPARATOR, FONT_SIZE_SELECT, LINK_INPUT, UNLINK_BUTTON, SEPARATOR, IMAGE_INPUT, FORE_COLOR];
class CommandService {
  constructor(document) {
    this.document = document;
  }
  isCommandWithValue(item) {
    return item.type === ToolbarItemType.Select || item.type === ToolbarItemType.Color;
  }
  isCommandWithState(item) {
    return item.type === ToolbarItemType.Button;
  }
  getQueryCommandState(buttons) {
    return buttons.filter(item => this.isCommandWithValue(item) || this.isCommandWithState(item)).reduce((acc, curr) => ({
      ...acc,
      [curr.command]: this.isCommandWithValue(curr) ? this.document.queryCommandValue(String(curr.command)) : this.document.queryCommandState(String(curr.command))
    }), {});
  }
  execCommand(command, value) {
    this.document.execCommand(command, false, value ? String(value) : value);
  }
  static {
    this.ɵfac = function CommandService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CommandService)(i0.ɵɵinject(DOCUMENT));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CommandService,
      factory: CommandService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CommandService, [{
    type: Injectable
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }];
  }, null);
})();
class EditorButtonComponent {
  constructor() {
    this.command = new EventEmitter();
  }
  onCommand(command) {
    this.command.emit(command);
  }
  static {
    this.ɵfac = function EditorButtonComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || EditorButtonComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: EditorButtonComponent,
      selectors: [["st-button"]],
      inputs: {
        button: "button",
        state: "state"
      },
      outputs: {
        command: "command"
      },
      decls: 2,
      vars: 5,
      consts: [[1, "st-button", "st-toolbar-item", 3, "click", "title"]],
      template: function EditorButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "button", 0);
          i0.ɵɵlistener("click", function EditorButtonComponent_Template_button_click_0_listener() {
            return ctx.onCommand(ctx.button == null ? null : ctx.button.command);
          });
          i0.ɵɵelement(1, "i");
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassProp("active", ctx.state);
          i0.ɵɵproperty("title", (ctx.button == null ? null : ctx.button.title) || "");
          i0.ɵɵadvance();
          i0.ɵɵclassMap(ctx.button == null ? null : ctx.button.icon);
        }
      },
      styles: [".st-toolbar-item[_ngcontent-%COMP%]{border:0;padding:8px 14px;background-color:#fff;border-radius:5px;margin-right:1px;cursor:pointer}.st-toolbar-item[_ngcontent-%COMP%]:hover{background-color:#e0e0e0}.st-toolbar-item[_ngcontent-%COMP%]:focus{outline-color:#424242}.st-button[_ngcontent-%COMP%]:hover{background-color:#e0e0e0}.st-button[_ngcontent-%COMP%]:active{transform:scale(.98);box-shadow:1px 1px 7px 1px #e0e0e0}.st-button.active[_ngcontent-%COMP%]{background-color:#424242;color:#fff}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditorButtonComponent, [{
    type: Component,
    args: [{
      selector: 'st-button',
      template: "<button class=\"st-button st-toolbar-item\"\n        [title]=\"button?.title || ''\"\n        (click)=\"onCommand(button?.command)\"\n        [class.active]=\"state\">\n  <i [class]=\"button?.icon\"></i>\n</button>\n",
      styles: [".st-toolbar-item{border:0;padding:8px 14px;background-color:#fff;border-radius:5px;margin-right:1px;cursor:pointer}.st-toolbar-item:hover{background-color:#e0e0e0}.st-toolbar-item:focus{outline-color:#424242}.st-button:hover{background-color:#e0e0e0}.st-button:active{transform:scale(.98);box-shadow:1px 1px 7px 1px #e0e0e0}.st-button.active{background-color:#424242;color:#fff}\n"]
    }]
  }], null, {
    button: [{
      type: Input
    }],
    state: [{
      type: Input
    }],
    command: [{
      type: Output
    }]
  });
})();
const DEFAULT_COLOR = '#000000';
class EditorColorPickerComponent {
  constructor() {
    this.command = new EventEmitter();
  }
  onCommand(command, value) {
    this.command.emit({
      command,
      value
    });
  }
  rgbStringToHex(rgb) {
    const match = String(rgb).match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
    return !match ? DEFAULT_COLOR : '#' + [match[1], match[2], match[3]].map(x => {
      const hex = Number(x).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    }).join('');
  }
  static {
    this.ɵfac = function EditorColorPickerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || EditorColorPickerComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: EditorColorPickerComponent,
      selectors: [["st-color-picker"]],
      inputs: {
        button: "button",
        state: "state"
      },
      outputs: {
        command: "command"
      },
      decls: 5,
      vars: 7,
      consts: [["colorPicker", ""], [1, "st-button", "st-toolbar-item", 3, "click", "title"], ["type", "color", "tabindex", "-1", "onmousedown", "return false;", "onselectstart", "return false;", 1, "st-color-picker", 3, "change", "ngModel"]],
      template: function EditorColorPickerComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "button", 1);
          i0.ɵɵlistener("click", function EditorColorPickerComponent_Template_button_click_0_listener() {
            i0.ɵɵrestoreView(_r1);
            const colorPicker_r2 = i0.ɵɵreference(3);
            return i0.ɵɵresetView(colorPicker_r2.click());
          });
          i0.ɵɵtext(1);
          i0.ɵɵelementStart(2, "input", 2, 0);
          i0.ɵɵlistener("change", function EditorColorPickerComponent_Template_input_change_2_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onCommand(ctx.button == null ? null : ctx.button.command, $event.target.value));
          });
          i0.ɵɵelementEnd();
          i0.ɵɵelement(4, "i");
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("title", (ctx.button == null ? null : ctx.button.title) || "");
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate1(" ", ctx.rgbStringToHex(ctx.state), " ");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngModel", ctx.rgbStringToHex(ctx.state));
          i0.ɵɵadvance(2);
          i0.ɵɵclassMap(ctx.button == null ? null : ctx.button.icon);
          i0.ɵɵstyleProp("color", ctx.state);
        }
      },
      dependencies: [i2.DefaultValueAccessor, i2.NgControlStatus, i2.NgModel],
      styles: [".st-toolbar-item[_ngcontent-%COMP%]{border:0;padding:8px 14px;background-color:#fff;border-radius:5px;margin-right:1px;cursor:pointer}.st-toolbar-item[_ngcontent-%COMP%]:hover{background-color:#e0e0e0}.st-toolbar-item[_ngcontent-%COMP%]:focus{outline-color:#424242}.st-button[_ngcontent-%COMP%]:hover{background-color:#e0e0e0}.st-button[_ngcontent-%COMP%]:active{transform:scale(.98);box-shadow:1px 1px 7px 1px #e0e0e0}.st-button.active[_ngcontent-%COMP%]{background-color:#424242;color:#fff}.st-color-picker[_ngcontent-%COMP%]{width:0;height:0;border:0;padding:0;margin:0;pointer-events:none}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditorColorPickerComponent, [{
    type: Component,
    args: [{
      selector: 'st-color-picker',
      template: "<button class=\"st-button st-toolbar-item\" [title]=\"button?.title || ''\" (click)=\"colorPicker.click()\">\n  {{rgbStringToHex(state)}}\n  <input #colorPicker class=\"st-color-picker\" type=\"color\" [ngModel]=\"rgbStringToHex(state)\" tabindex=\"-1\"\n         (change)=\"onCommand(button?.command, $event.target.value)\" onmousedown='return false;' onselectstart='return false;'>\n  <i [class]=\"button?.icon\" [style.color]=\"state\"></i>\n</button>\n",
      styles: [".st-toolbar-item{border:0;padding:8px 14px;background-color:#fff;border-radius:5px;margin-right:1px;cursor:pointer}.st-toolbar-item:hover{background-color:#e0e0e0}.st-toolbar-item:focus{outline-color:#424242}.st-button:hover{background-color:#e0e0e0}.st-button:active{transform:scale(.98);box-shadow:1px 1px 7px 1px #e0e0e0}.st-button.active{background-color:#424242;color:#fff}.st-color-picker{width:0;height:0;border:0;padding:0;margin:0;pointer-events:none}\n"]
    }]
  }], null, {
    button: [{
      type: Input
    }],
    state: [{
      type: Input
    }],
    command: [{
      type: Output
    }]
  });
})();
const APPROX_WIDTH_THRESHOLD = 278;
const MARGIN_OFFSET = -150;
class EditorInputComponent {
  constructor(element, document) {
    this.element = element;
    this.document = document;
    this.command = new EventEmitter();
    this.showInputWindow = false;
    this.value = '';
    this.margin = 0; // workaround fox window width computing
    this.opacity = 0; // workaround fox window width computing
  }
  onCommand() {
    if (this.value) {
      if (this.selectionRange) {
        this.loadSelection();
      }
      this.closeInputWindow();
      this.command.emit({
        command: this.button.command,
        value: this.button.transform ? this.button.transform(this.value) : this.value
      });
      this.value = '';
    }
  }
  openInputWindow() {
    this.saveSelection();
    this.showInputWindow = true;
    setTimeout(() => {
      this.margin = this.windowElement.nativeElement.getBoundingClientRect().width < APPROX_WIDTH_THRESHOLD ? MARGIN_OFFSET : 0;
      this.opacity = 1;
      this.inputElement.nativeElement.focus();
    });
  }
  closeInputWindow() {
    this.showInputWindow = false;
    this.margin = 0;
    this.opacity = 0;
  }
  outsideClick($event) {
    if (!this.element.nativeElement.contains($event.target) && this.showInputWindow) {
      this.closeInputWindow();
    }
  }
  loadSelection() {
    const selection = this.document.defaultView.getSelection();
    selection.removeAllRanges();
    selection.addRange(this.selectionRange);
    this.selectionRange = null;
  }
  saveSelection() {
    const selection = this.document.defaultView.getSelection();
    this.selectionRange = selection.rangeCount === 0 ? null : selection.getRangeAt(0);
  }
  static {
    this.ɵfac = function EditorInputComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || EditorInputComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(DOCUMENT));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: EditorInputComponent,
      selectors: [["st-input"]],
      viewQuery: function EditorInputComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
          i0.ɵɵviewQuery(_c1, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputElement = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.windowElement = _t.first);
        }
      },
      hostBindings: function EditorInputComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function EditorInputComponent_click_HostBindingHandler($event) {
            return ctx.outsideClick($event);
          }, false, i0.ɵɵresolveDocument);
        }
      },
      inputs: {
        button: "button",
        state: "state"
      },
      outputs: {
        command: "command"
      },
      decls: 2,
      vars: 3,
      consts: [["window", ""], ["input", ""], [3, "command", "button", "state"], ["class", "st-input-window", 3, "margin-left", "opacity", "keyup.escape", 4, "ngIf"], [1, "st-input-window", 3, "keyup.escape"], ["class", "st-input-label", 4, "ngIf"], ["type", "text", 1, "st-input-input", 3, "ngModelChange", "title", "ngModel"], [1, "st-input-button", 3, "click"], [1, "st-input-label"]],
      template: function EditorInputComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "st-button", 2);
          i0.ɵɵlistener("command", function EditorInputComponent_Template_st_button_command_0_listener() {
            return ctx.openInputWindow();
          });
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(1, EditorInputComponent_div_1_Template, 8, 7, "div", 3);
        }
        if (rf & 2) {
          i0.ɵɵproperty("button", ctx.button)("state", ctx.state);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.showInputWindow);
        }
      },
      dependencies: [i2$1.NgIf, i2.DefaultValueAccessor, i2.NgControlStatus, i2.NgModel, EditorButtonComponent],
      styles: [".st-toolbar-item[_ngcontent-%COMP%], .st-input-button[_ngcontent-%COMP%]{border:0;padding:8px 14px;background-color:#fff;border-radius:5px;margin-right:1px;cursor:pointer}.st-toolbar-item[_ngcontent-%COMP%]:hover, .st-input-button[_ngcontent-%COMP%]:hover{background-color:#e0e0e0}.st-toolbar-item[_ngcontent-%COMP%]:focus, .st-input-button[_ngcontent-%COMP%]:focus{outline-color:#424242}.st-input-window[_ngcontent-%COMP%]{display:flex;flex-direction:column;background-color:#fff;position:absolute;padding:10px;top:5px;border:1px solid #bdbdbd;border-radius:5px;box-shadow:0 3px 7px #bdbdbd}.st-input-input[_ngcontent-%COMP%]{padding:8px 14px;background-color:#fff;margin-right:-1px;border:1px solid #bdbdbd;border-bottom-left-radius:5px;border-top-left-radius:5px}.st-input-button[_ngcontent-%COMP%]{margin-right:0;border-bottom-left-radius:0;border-top-left-radius:0;border:1px solid #bdbdbd}.st-input-label[_ngcontent-%COMP%]{font-size:14px;font-weight:700}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditorInputComponent, [{
    type: Component,
    args: [{
      selector: 'st-input',
      template: "<st-button [button]=\"button\" [state]=\"state\" (command)=\"openInputWindow()\"></st-button>\n<div *ngIf=\"showInputWindow\" #window class=\"st-input-window\" (keyup.escape)=\"closeInputWindow()\" [style.margin-left.px]=\"margin\" [style.opacity]=\"opacity\">\n  <label *ngIf=\"button?.label\" class=\"st-input-label\">{{button?.label}}</label>\n  <div>\n    <input #input class=\"st-input-input\" type=\"text\" [title]=\"button?.label\" [(ngModel)]=\"value\">\n    <button class=\"st-input-button\" (click)=\"onCommand()\">Confirm</button>\n  </div>\n</div>\n",
      styles: [".st-toolbar-item,.st-input-button{border:0;padding:8px 14px;background-color:#fff;border-radius:5px;margin-right:1px;cursor:pointer}.st-toolbar-item:hover,.st-input-button:hover{background-color:#e0e0e0}.st-toolbar-item:focus,.st-input-button:focus{outline-color:#424242}.st-input-window{display:flex;flex-direction:column;background-color:#fff;position:absolute;padding:10px;top:5px;border:1px solid #bdbdbd;border-radius:5px;box-shadow:0 3px 7px #bdbdbd}.st-input-input{padding:8px 14px;background-color:#fff;margin-right:-1px;border:1px solid #bdbdbd;border-bottom-left-radius:5px;border-top-left-radius:5px}.st-input-button{margin-right:0;border-bottom-left-radius:0;border-top-left-radius:0;border:1px solid #bdbdbd}.st-input-label{font-size:14px;font-weight:700}\n"]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }];
  }, {
    button: [{
      type: Input
    }],
    state: [{
      type: Input
    }],
    command: [{
      type: Output
    }],
    inputElement: [{
      type: ViewChild,
      args: ['input', {
        static: false
      }]
    }],
    windowElement: [{
      type: ViewChild,
      args: ['window', {
        static: false
      }]
    }],
    outsideClick: [{
      type: HostListener,
      args: ['document:click', ['$event']]
    }]
  });
})();
class EditorSelectComponent {
  constructor() {
    this.command = new EventEmitter();
  }
  ngOnInit() {
    if (!this.state && this.button.items.length) {
      this.state = this.button.items[0].value;
    }
  }
  onCommand(command, value) {
    this.command.emit({
      command,
      value
    });
  }
  static {
    this.ɵfac = function EditorSelectComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || EditorSelectComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: EditorSelectComponent,
      selectors: [["st-select"]],
      inputs: {
        button: "button",
        state: "state"
      },
      outputs: {
        command: "command"
      },
      decls: 2,
      vars: 3,
      consts: [[1, "st-select", "st-toolbar-item", 3, "ngModelChange", "ngModel", "title"], [3, "value", 4, "ngFor", "ngForOf"], [3, "value"]],
      template: function EditorSelectComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "select", 0);
          i0.ɵɵlistener("ngModelChange", function EditorSelectComponent_Template_select_ngModelChange_0_listener($event) {
            return ctx.onCommand(ctx.button == null ? null : ctx.button.command, $event);
          });
          i0.ɵɵtemplate(1, EditorSelectComponent_option_1_Template, 2, 2, "option", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngModel", ctx.state)("title", (ctx.button == null ? null : ctx.button.title) || "");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngForOf", ctx.button == null ? null : ctx.button.items);
        }
      },
      dependencies: [i2$1.NgForOf, i2.NgSelectOption, i2.ɵNgSelectMultipleOption, i2.SelectControlValueAccessor, i2.NgControlStatus, i2.NgModel],
      styles: [".st-toolbar-item[_ngcontent-%COMP%]{border:0;padding:8px 14px;background-color:#fff;border-radius:5px;margin-right:1px;cursor:pointer}.st-toolbar-item[_ngcontent-%COMP%]:hover{background-color:#e0e0e0}.st-toolbar-item[_ngcontent-%COMP%]:focus{outline-color:#424242}option[_ngcontent-%COMP%]:checked{background-color:#424242;color:#fff}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditorSelectComponent, [{
    type: Component,
    args: [{
      selector: 'st-select',
      template: "<select [ngModel]=\"state\" [title]=\"button?.title || ''\" (ngModelChange)=\"onCommand(button?.command, $event)\" class=\"st-select st-toolbar-item\">\n  <option *ngFor=\"let i of button?.items\" [value]=\"i.value\">{{i.label}}</option>\n</select>\n",
      styles: [".st-toolbar-item{border:0;padding:8px 14px;background-color:#fff;border-radius:5px;margin-right:1px;cursor:pointer}.st-toolbar-item:hover{background-color:#e0e0e0}.st-toolbar-item:focus{outline-color:#424242}option:checked{background-color:#424242;color:#fff}\n"]
    }]
  }], null, {
    button: [{
      type: Input
    }],
    state: [{
      type: Input
    }],
    command: [{
      type: Output
    }]
  });
})();
class EditorSeparatorComponent {
  static {
    this.ɵfac = function EditorSeparatorComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || EditorSeparatorComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: EditorSeparatorComponent,
      selectors: [["st-separator"]],
      decls: 2,
      vars: 0,
      consts: [[1, "st-divider"]],
      template: function EditorSeparatorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtext(1, "\xA0");
          i0.ɵɵelementEnd();
        }
      },
      styles: [".st-toolbar-item[_ngcontent-%COMP%]{border:0;padding:8px 14px;background-color:#fff;border-radius:5px;margin-right:1px;cursor:pointer}.st-toolbar-item[_ngcontent-%COMP%]:hover{background-color:#e0e0e0}.st-toolbar-item[_ngcontent-%COMP%]:focus{outline-color:#424242}.st-divider[_ngcontent-%COMP%]{border-right:1px solid #bdbdbd;margin:0 5px 0 0;height:25px}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditorSeparatorComponent, [{
    type: Component,
    args: [{
      selector: 'st-separator',
      template: "<div class=\"st-divider\">&nbsp;</div>\n",
      styles: [".st-toolbar-item{border:0;padding:8px 14px;background-color:#fff;border-radius:5px;margin-right:1px;cursor:pointer}.st-toolbar-item:hover{background-color:#e0e0e0}.st-toolbar-item:focus{outline-color:#424242}.st-divider{border-right:1px solid #bdbdbd;margin:0 5px 0 0;height:25px}\n"]
    }]
  }], null, null);
})();
const MUTATION_OBSERVER_CONFIG = {
  attributes: true,
  childList: true,
  subtree: true,
  characterData: true
};
class DomModifyDirective {
  constructor(element) {
    this.element = element;
    this.domModify = new EventEmitter();
  }
  ngOnInit() {
    this.observer = new MutationObserver(() => {
      this.domModify.emit();
    });
    this.observer.observe(this.element.nativeElement, MUTATION_OBSERVER_CONFIG);
  }
  ngOnDestroy() {
    this.observer.disconnect();
  }
  static {
    this.ɵfac = function DomModifyDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DomModifyDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: DomModifyDirective,
      selectors: [["", "stDomModify", ""]],
      outputs: {
        domModify: "stDomModify"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DomModifyDirective, [{
    type: Directive,
    args: [{
      selector: '[stDomModify]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    domModify: [{
      type: Output,
      args: ['stDomModify']
    }]
  });
})();
class SelectionChangeDirective {
  constructor(document, element) {
    this.document = document;
    this.element = element;
    this.selectionChange = new EventEmitter();
  }
  selectionchange() {
    if (this.document.activeElement === this.element.nativeElement) {
      this.selectionChange.emit();
    }
  }
  static {
    this.ɵfac = function SelectionChangeDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SelectionChangeDirective)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: SelectionChangeDirective,
      selectors: [["", "stSelectionChange", ""]],
      hostBindings: function SelectionChangeDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("selectionchange", function SelectionChangeDirective_selectionchange_HostBindingHandler() {
            return ctx.selectionchange();
          }, false, i0.ɵɵresolveDocument);
        }
      },
      outputs: {
        selectionChange: "stSelectionChange"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SelectionChangeDirective, [{
    type: Directive,
    args: [{
      selector: '[stSelectionChange]'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: i0.ElementRef
    }];
  }, {
    selectionChange: [{
      type: Output,
      args: ['stSelectionChange']
    }],
    selectionchange: [{
      type: HostListener,
      args: ['document:selectionchange']
    }]
  });
})();
class EditorDropdownComponent {
  constructor() {
    this.label = '';
    this.command = new EventEmitter();
  }
  set button(val) {
    this.label = val.label;
    if (this.width) {
      this._button = val;
    } else {
      this._tempButton = val;
    }
  }
  get button() {
    return this._button;
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.width = this.selectElement.nativeElement.getBoundingClientRect().width;
      this._button = this._tempButton;
    });
  }
  onCommand({
    value: label
  }) {
    const {
      command,
      value
    } = this.button.items.find(item => item.label === label);
    this.command.emit({
      command,
      value
    });
  }
  static {
    this.ɵfac = function EditorDropdownComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || EditorDropdownComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: EditorDropdownComponent,
      selectors: [["st-dropdown"]],
      viewQuery: function EditorDropdownComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c2, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.selectElement = _t.first);
        }
      },
      inputs: {
        button: "button"
      },
      outputs: {
        command: "command"
      },
      decls: 5,
      vars: 5,
      consts: [["select", ""], [1, "st-select", "st-toolbar-item", 3, "change", "title"], ["value", "", "disabled", "", "selected", "", "hidden", ""], [3, "value", 4, "ngFor", "ngForOf"], [3, "value"]],
      template: function EditorDropdownComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "select", 1, 0);
          i0.ɵɵlistener("change", function EditorDropdownComponent_Template_select_change_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            const select_r2 = i0.ɵɵreference(1);
            ctx.onCommand($event.target);
            return i0.ɵɵresetView(select_r2.value = "");
          });
          i0.ɵɵelementStart(2, "option", 2);
          i0.ɵɵtext(3);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(4, EditorDropdownComponent_option_4_Template, 2, 2, "option", 3);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵstyleProp("width", ctx.width, "px");
          i0.ɵɵproperty("title", (ctx.button == null ? null : ctx.button.title) || "");
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate(ctx.label);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngForOf", ctx.button == null ? null : ctx.button.items);
        }
      },
      dependencies: [i2$1.NgForOf, i2.NgSelectOption, i2.ɵNgSelectMultipleOption],
      styles: [".st-toolbar-item[_ngcontent-%COMP%]{border:0;padding:8px 14px;background-color:#fff;border-radius:5px;margin-right:1px;cursor:pointer}.st-toolbar-item[_ngcontent-%COMP%]:hover{background-color:#e0e0e0}.st-toolbar-item[_ngcontent-%COMP%]:focus{outline-color:#424242}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditorDropdownComponent, [{
    type: Component,
    args: [{
      selector: 'st-dropdown',
      template: "<select #select [title]=\"button?.title || ''\" (change)=\"onCommand($event.target); select.value = ''\" class=\"st-select st-toolbar-item\" [style.width.px]=\"this.width\">\n  <option value=\"\" disabled selected hidden>{{label}}</option>\n  <option *ngFor=\"let i of button?.items\" [value]=\"i.label\">{{i.label}}</option>\n</select>\n",
      styles: [".st-toolbar-item{border:0;padding:8px 14px;background-color:#fff;border-radius:5px;margin-right:1px;cursor:pointer}.st-toolbar-item:hover{background-color:#e0e0e0}.st-toolbar-item:focus{outline-color:#424242}\n"]
    }]
  }], null, {
    selectElement: [{
      type: ViewChild,
      args: ['select']
    }],
    button: [{
      type: Input
    }],
    command: [{
      type: Output
    }]
  });
})();
const DEFAULT_CONFIG = {
  placeholder: '',
  buttons: ST_BUTTONS
};
class EditorComponent {
  set config(val) {
    this._config = {
      ...DEFAULT_CONFIG,
      ...val
    };
  }
  get config() {
    return this._config;
  }
  constructor(document, commandService) {
    this.document = document;
    this.commandService = commandService;
    this._config = DEFAULT_CONFIG;
    this.content = '';
    this.toolbarItemType = ToolbarItemType;
    this.isDisabled = false;
    this.queryCommandState = {};
  }
  ngAfterViewInit() {
    this.updateContentEditable();
  }
  writeValue(val) {
    this.content = val;
    this.updateContentEditable();
  }
  registerOnChange(fn) {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn) {
    this.onTouchedFn = fn;
  }
  setDisabledState(isDisabled) {
    this.isDisabled = isDisabled;
  }
  domModify() {
    if (this.contentEditable) {
      this.onChangeFn(this.contentEditable.nativeElement.innerHTML);
    }
  }
  execCommand(command, value) {
    this.contentEditable.nativeElement.focus();
    this.commandService.execCommand(command, value);
    this.fetchQueryCommandState();
  }
  fetchQueryCommandState() {
    this.queryCommandState = this.commandService.getQueryCommandState(this.config.buttons);
  }
  trackBy(_, item) {
    return item.name;
  }
  updateContentEditable() {
    if (this.contentEditable) {
      this.contentEditable.nativeElement.innerHTML = this.content;
    }
  }
  static {
    this.ɵfac = function EditorComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || EditorComponent)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(CommandService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: EditorComponent,
      selectors: [["st-editor"]],
      viewQuery: function EditorComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c3, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.contentEditable = _t.first);
        }
      },
      inputs: {
        config: "config"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: EditorComponent,
        multi: true
      }, CommandService])],
      decls: 5,
      vars: 6,
      consts: [["contentEditable", ""], [1, "st-editor-container"], ["tabIndex", "0", 1, "st-toolbar"], [3, "ngSwitch", 4, "ngFor", "ngForOf", "ngForTrackBy"], [1, "st-area", 3, "stDomModify", "stSelectionChange", "blur"], [3, "ngSwitch"], [4, "ngSwitchCase"], [3, "button", "state", "command", 4, "ngIf"], [3, "button", "state", "command", 4, "ngSwitchCase"], [3, "button", "command", 4, "ngSwitchCase"], [3, "command", "button", "state"], [3, "command", "button"]],
      template: function EditorComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "div", 1)(1, "div", 2);
          i0.ɵɵtemplate(2, EditorComponent_ng_container_2_Template, 7, 7, "ng-container", 3);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "div", 4, 0);
          i0.ɵɵlistener("stDomModify", function EditorComponent_Template_div_stDomModify_3_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.domModify());
          })("stSelectionChange", function EditorComponent_Template_div_stSelectionChange_3_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.fetchQueryCommandState());
          })("blur", function EditorComponent_Template_div_blur_3_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onTouchedFn());
          });
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵclassProp("disabled", ctx.isDisabled);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngForOf", ctx.config.buttons)("ngForTrackBy", ctx.trackBy);
          i0.ɵɵadvance();
          i0.ɵɵattribute("data-placeholder", ctx.config.placeholder)("contenteditable", !ctx.isDisabled);
        }
      },
      dependencies: [i2$1.NgForOf, i2$1.NgIf, i2$1.NgSwitch, i2$1.NgSwitchCase, EditorButtonComponent, EditorColorPickerComponent, EditorInputComponent, EditorSelectComponent, EditorSeparatorComponent, DomModifyDirective, SelectionChangeDirective, EditorDropdownComponent],
      styles: [".st-toolbar-item[_ngcontent-%COMP%]{border:0;padding:8px 14px;background-color:#fff;border-radius:5px;margin-right:1px;cursor:pointer}.st-toolbar-item[_ngcontent-%COMP%]:hover{background-color:#e0e0e0}.st-toolbar-item[_ngcontent-%COMP%]:focus{outline-color:#424242}.st-editor-container[_ngcontent-%COMP%]{position:relative}.st-editor-container.disabled[_ngcontent-%COMP%]{pointer-events:none;opacity:.5}.st-area[_ngcontent-%COMP%]{resize:vertical;overflow:auto;border:1px solid #bdbdbd;border-top:0;border-bottom-left-radius:5px;border-bottom-right-radius:5px;min-height:200px;padding:5px;background-color:#fff}.st-area[_ngcontent-%COMP%]:focus{outline-color:#424242}.st-area[_ngcontent-%COMP%]:empty:not(:focus):before{content:attr(data-placeholder);color:#bdbdbd;pointer-events:none}.st-toolbar[_ngcontent-%COMP%]{border-top-right-radius:5px;border-top-left-radius:5px;display:flex;flex-wrap:wrap;align-items:center;border:1px solid #bdbdbd;padding:5px;background-color:#fff}.st-toolbar[_ngcontent-%COMP%]:focus{outline-color:#424242}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EditorComponent, [{
    type: Component,
    args: [{
      selector: 'st-editor',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: EditorComponent,
        multi: true
      }, CommandService],
      template: "<div class=\"st-editor-container\" [class.disabled]=\"isDisabled\">\n  <div class=\"st-toolbar\" tabIndex=\"0\">\n    <ng-container *ngFor=\"let item of config.buttons; trackBy:trackBy\" [ngSwitch]=\"item.type\">\n      <st-separator *ngSwitchCase=\"toolbarItemType.Separator\"></st-separator>\n      <st-button *ngIf=\"item.type === toolbarItemType.Button\"\n                                 [button]=\"item\" [state]=\"queryCommandState[item.command]\"\n                                 (command)=\"execCommand($event)\">\n      </st-button>\n      <st-select *ngSwitchCase=\"toolbarItemType.Select\"\n                         [button]=\"item\" [state]=\"queryCommandState[item.command]\"\n                         (command)=\"execCommand($event.command, $event.value)\">\n      </st-select>\n      <st-color-picker *ngSwitchCase=\"toolbarItemType.Color\"\n                               [button]=\"item\" [state]=\"queryCommandState[item.command]\"\n                               (command)=\"execCommand($event.command, $event.value)\">\n      </st-color-picker>\n      <st-input *ngSwitchCase=\"toolbarItemType.Input\"\n                        [button]=\"item\"\n                        (command)=\"execCommand($event.command, $event.value)\">\n      </st-input>\n      <st-dropdown *ngSwitchCase=\"toolbarItemType.Dropdown\"\n                [button]=\"item\"\n                (command)=\"execCommand($event.command, $event.value)\">\n      </st-dropdown>\n    </ng-container>\n  </div>\n  <div #contentEditable class=\"st-area\"\n       [attr.data-placeholder]=\"config.placeholder\" [attr.contenteditable]=\"!isDisabled\"\n       (stDomModify)=\"domModify()\" (stSelectionChange)=\"fetchQueryCommandState()\" (blur)=\"onTouchedFn()\"></div>\n</div>\n",
      styles: [".st-toolbar-item{border:0;padding:8px 14px;background-color:#fff;border-radius:5px;margin-right:1px;cursor:pointer}.st-toolbar-item:hover{background-color:#e0e0e0}.st-toolbar-item:focus{outline-color:#424242}.st-editor-container{position:relative}.st-editor-container.disabled{pointer-events:none;opacity:.5}.st-area{resize:vertical;overflow:auto;border:1px solid #bdbdbd;border-top:0;border-bottom-left-radius:5px;border-bottom-right-radius:5px;min-height:200px;padding:5px;background-color:#fff}.st-area:focus{outline-color:#424242}.st-area:empty:not(:focus):before{content:attr(data-placeholder);color:#bdbdbd;pointer-events:none}.st-toolbar{border-top-right-radius:5px;border-top-left-radius:5px;display:flex;flex-wrap:wrap;align-items:center;border:1px solid #bdbdbd;padding:5px;background-color:#fff}.st-toolbar:focus{outline-color:#424242}\n"]
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: CommandService
    }];
  }, {
    config: [{
      type: Input
    }],
    contentEditable: [{
      type: ViewChild,
      args: ['contentEditable']
    }]
  });
})();
class NgxSimpleTextEditorModule {
  static {
    this.ɵfac = function NgxSimpleTextEditorModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgxSimpleTextEditorModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxSimpleTextEditorModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FormsModule, ReactiveFormsModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxSimpleTextEditorModule, [{
    type: NgModule,
    args: [{
      declarations: [EditorComponent, EditorButtonComponent, EditorColorPickerComponent, EditorInputComponent, EditorSelectComponent, EditorSeparatorComponent, DomModifyDirective, SelectionChangeDirective, EditorDropdownComponent],
      imports: [CommonModule, FormsModule, ReactiveFormsModule],
      exports: [EditorComponent, EditorButtonComponent, EditorColorPickerComponent, EditorInputComponent, EditorSelectComponent, EditorSeparatorComponent, EditorDropdownComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-simple-text-editor
 */

/**
 * Generated bundle index. Do not edit.
 */

export { BOLD_BUTTON, CUSTOM, EditorButtonComponent, EditorColorPickerComponent, EditorComponent, EditorDropdownComponent, EditorInputComponent, EditorSelectComponent, EditorSeparatorComponent, FONT_SIZE_SELECT, FORE_COLOR, IMAGE_INPUT, INDENT_BUTTON, ITALIC_BUTTON, JUSTIFY_CENTER_BUTTON, JUSTIFY_FULL_BUTTON, JUSTIFY_LEFT_BUTTON, JUSTIFY_RIGHT_BUTTON, LINK_INPUT, NgxSimpleTextEditorModule, ORDERED_LIST_BUTTON, OUTDENT_BUTTON, REDO_BUTTON, REMOVE_FORMAT_BUTTON, SEPARATOR, STRIKE_THROUGH_BUTTON, ST_BUTTONS, SUBSCRIPT_BUTTON, SUPERSCRIPT_BUTTON, ToolbarItemType, UNDERLINE_BUTTON, UNDO_BUTTON, UNLINK_BUTTON, UNORDERED_LIST_BUTTON };
