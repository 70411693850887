import { formatNumber } from "@angular/common";
import { ActivityCategory } from "./activity_category";
import { ActivityImage } from "./activity_image";
import { Entity, ModelI, TableColumn } from "./model";
import { Location } from "./location";
import { ActivityDate } from "./activity_date";
import { ActivityPrice } from "./activity_price";
import { ActivityDetail } from "./activity_detail";
import { ActivityComment } from "./activity_comment";
import { Language } from "./language";
import { ActivityRegularity } from "./activity_regularity";
import { Supplier } from "./supplier";
import { ActivityBookmark } from "./activity_bookmark";
import { ActivityBooking } from "./activity_booking";
import { Tag } from "./tag";

@Entity({
    url: "/api/v1/activity"
})
export class Activity implements ModelI {
    id!: number;
    reference!: string;
    title!: string;
    display_title!: string;
    slug!: string;
    summary!: string;
    description!: string;
    base_price!: number;
    duration_quantity!: number;
    duration_unit!: "days" | "hours" | "minutes";
    book_before!: number;
    cancel_before!: number;
    min_participants!: number;
    expected_participants!: number;
    max_participants!: number;
    status!: number;
    featured!: boolean;
    departure_address!: string;

    visit_count!: number;
    bookmark_count!: number;
    participant_count!: number;
    comments_count!: number;

    promoted!: boolean;
    rating!: number;
    language_id!: number;
    language!: Language;
    tags?: Tag[];
    category_id!: number;
    category?: ActivityCategory;
    regularity_id!: number;
    regularity?: ActivityRegularity;
    supplier_id!: number;
    supplier!: Supplier;
    origin_id!: number;
    origin!: Location;
    destination_id!: number;
    destination!: Location;
    bookmarks?: ActivityBookmark[];
    dates?: ActivityDate[];
    prices?: ActivityPrice[];
    bookings?: ActivityBooking[];
    images?: ActivityImage[];
    details?: ActivityDetail[]
    comments?: ActivityComment[];
    // cache?: ActivityCache[];
    created_at!: string;
    created_by!: string;
    updated_at!: string;
    updated_by!: string;
    deleted_at!: string;
    deleted_by!: string;
    search!: string;
    accepted!: boolean;
    accepted_at!: string | null;
    accepted_by!: string | null;
    cached!: boolean;
    cached_at!: string;
    bookmarked!: boolean;
}
