import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'navbar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
  ],
  template: `
    <aside class="bg-accent text-light" style="min-width: 300px;">
      <header class="d-flex align-items-center justify-content-center border-bottom border-accent-subtle px-5">
        <a routerLink="/" class="navbar-brand">
          <img src="assets/img/logo-white.png" alt="Logo" width="110">
        </a>
      </header>
      <div class="px-5">
        <ul class="nav mt-5 flex-column">
          <li class="nav-item">
            <a class="nav-link text-reset bg-hover-accent-subtle rounded-4 py-3 px-4"
              routerLinkActive="bg-accent-subtle" routerLink="/profile">
              <i class="fa-solid fa-table-columns"></i>
              <span class="ps-2">Dashboard</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-reset bg-hover-accent-subtle rounded-4 py-3 px-4"
              routerLinkActive="bg-accent-subtle" routerLink="/shopping-cart">
              <i class="fa-solid fa-table-columns"></i>
              <span class="ps-2">Shopping Cart</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-reset bg-hover-accent-subtle rounded-4 py-3 px-4"
              routerLinkActive="bg-accent-subtle" routerLink="/bookings">
              <i class="fa-solid fa-table-columns"></i>
              <span class="ps-2">My Booking</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-reset bg-hover-accent-subtle rounded-4 py-3 px-4"
              routerLinkActive="bg-accent-subtle" routerLink="/wishlists">
              <i class="fa-solid fa-table-columns"></i>
              <span class="ps-2" i18n>Wishlists</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-reset bg-hover-accent-subtle rounded-4 py-3 px-4"
              routerLinkActive="bg-accent-subtle" routerLink="/other">
              <i class="fa-solid fa-table-columns"></i>
              <span class="ps-2">My Profile</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-reset bg-hover-accent-subtle rounded-4 py-3 px-4"
              routerLinkActive="bg-accent-subtle" routerLink="/other">
              <i class="fa-solid fa-table-columns"></i>
              <span class="ps-2">Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </aside>
  `,
  styles: "header { min-height: 90px; }",
  host: { class: 'bg-accent text-light min-vh-100' },
})
export class ClientNavbar {
}