<div class="card">
  <div class="card-title text-center border px-3 py-2 m-0">
    @if (!start || start.valueOf() < current.valueOf()) {
      <span class="float-start text-main" role="button" (click)="prevMonth()">
      <i class="fa-solid fa-circle-chevron-left"></i>
      </span>
    }
    <span class="text-main fw-bold">
      {{ getMonthName() }} {{ current.getFullYear() }}
    </span>
    @if (!end || getYearAndMonth(end) > getYearAndMonth(current)) {
    <span class="float-end text-main" role="button" (click)="nextMonth()">
      <i class="fa-solid fa-circle-chevron-right"></i>
    </span>
    }
  </div>
  <div class="card-body p-0">
    <div class="container-fluid text-center">
      <div class="row">
        @for (day of weekDays; track day) {
        <div class="col bg-body-secondary text-main small py-2 px-0 border border-2 border-white">{{ day }}</div>
        }
      </div>
      <div class="row">
        @for (date of monthArray; track date.date) {
        @if (date.status == "selected") {
        <div role="button"
          class="col col-day text-nowrap px-0 bg-main text-light rounded rounded-3 small py-2 border border-2 border-white"
          (click)="openDate(date)" [title]="date.date | date:'mediumDate'">
          {{ date.date.getDate() }}
        </div>
        }
        @else if (date.status == "open") {
        <div role="button"
          class="col col-day text-nowrap px-0 bg-main-subtle small py-2 border border-2 border-white"
          (click)="openDate(date)">
          {{ date.date.getDate() }}
        </div>
        }
        @else if (date.status == "close") {
        <div class="col col-day text-nowrap px-0 bg-light text-black-50 small py-2 border border-2 border-white">
          {{ date.date.getDate() }}
        </div>
        }
        @else if (date.status == 'none') {
        <div class="col col-day text-nowrap px-0 bg-light py-2 border border-2 border-white"> </div>
        }
        }
      </div>
    </div>
  </div>
</div>