<div class="container-fluid min-vh-100 p-0">
  <div class="d-flex">
    <navbar></navbar>

    <div class="bg-light flex-grow-1">
      <menu></menu>
      <main class="container-fluid p-5">
        <h1 class="fs-2">Dashboard</h1>
        <p>Descripción</p>
        <div class="border rounded-4 bg-white mt-5 p-5">
          BODY
        </div>
      </main>
      <footer></footer>
    </div>
  </div>
</div>