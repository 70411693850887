import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getEnv } from './config.service';
import { CryptoService } from './crypto.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    key = {
        user: this.crypto.encrypt("user"),
        token: this.crypto.encrypt("token"),
    }

    constructor(
        private http: HttpClient,
        private crypto: CryptoService,
        private storage: StorageService
    ) {
        //
    }

    private getUrl(scope: "supplier" | "admin" | "client") {
        const baseUrl = getEnv("API_URL");
        let endpoint: string;
        switch (scope) {
            case "supplier":
                endpoint = "/api/v1/supplier/login";
                break;
            case "admin":
                endpoint = "/api/v1/admin/login";
                break;

            default:
                endpoint = "/api/v1/login";
                break;
        }

        return `${baseUrl}${endpoint}`;
    }

    getLoginPath(): string {
        const scope = this.token?.scopes[0];
        let routeName: string;

        switch (scope) {
            case "supplier":
                routeName = "suppliers.login";
                break;
            case "admin":
                routeName = "admin.login";
                break;

            default:
                routeName = "login";
                break;
        }

        return routeName;
    }

    redirectTo(): string {
        const scope = this.token?.scopes[0];
        let routeName: string;

        switch (scope) {
            case "supplier":
                routeName = "suppliers.dashboard";
                break;
            case "admin":
                routeName = "admin.dashboard";
                break;

            default:
                routeName = "dashboard";
                break;
        }

        return routeName;
    }

    register(scope: "supplier" | "admin" | "client", credentials: RegisterCredentialsI): Promise<any> {
        let endpoint: string;

        switch (scope) {
            case "supplier":
                endpoint = "api/v1/supplier/register";
                break;
            case "admin":
                throw new Error("No se pueden registrar administradores");
                break;
        
            default:
                endpoint = "api/v1/register";
                break;
        }
        return new Promise((resolve, reject) => {
            this.http.post<LoginResponse>(`${getEnv("API_URL")}/${endpoint}`, credentials)
                .subscribe({
                    next: (response) => {
                        resolve(response);
                    },
                    error(err) {
                        reject(err);
                    },
                })
        });
    }

    requestPaswordChange(credentials: any) {
        return new Promise((resolve, reject) => {
            this.http.post(`${getEnv("API_URL")}/api/v1/password/forgot`, credentials)
                .subscribe({
                    next: (response) => {
                        resolve(response);
                    },
                    error(err) {
                        reject(err);
                    },
                })
        });
    }

    paswordChange(credentials: any) {
        return new Promise((resolve, reject) => {
            this.http.post(`${getEnv("API_URL")}/api/v1/password/recover`, credentials)
                .subscribe({
                    next: (response) => {
                        resolve(response);
                    },
                    error(err) {
                        reject(err);
                    },
                })
        });
    }

    login(scope: "supplier" | "admin" | "client", credentials: LoginCredentialsI): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.post<LoginResponse>(this.getUrl(scope), credentials)
                .subscribe({
                    next: (response) => {
                        resolve(response);
                        this.store(this.key.user, response.user);
                        this.store(this.key.token, {
                            access: response.access_token,
                            expire: response.expires_at,
                            scopes: response.scopes,
                        });
                    },
                    error(err) {
                        reject(err);
                    },
                })
        });
    }

    logout() {
        this.storage.delete(this.key.user);
        this.storage.delete(this.key.token);
    }

    get user(): LoginResponse["user"] | undefined {
        return this.read(this.key.user);
    }

    get token(): {
        access: LoginResponse["access_token"],
        expire: LoginResponse["expires_at"],
        scopes: LoginResponse["scopes"],

    } | undefined {
        return this.read(this.key.token);
    }

    get bearer(): LoginResponse["access_token"] | undefined {
        return this.token?.access;
    }

    tokenValid(scope: string): boolean {
        if (this.token?.expire && this.token?.scopes.includes(scope)) {
            return new Date(this.token.expire) >= new Date();
        }

        return false;
    }

    private store(key: string, data: any): void {
        this.storage.set(key, data);
    }

    private read(key: string): any {
        return this.storage.get(key);
    }
}

export interface RegisterCredentialsI {
    username: string;
    password: string;
    name: string;
    document_id: string;
    email: string;
}

export interface LoginCredentialsI {
    username: string;
    password: string;
}

export interface LoginResponse {
    access_token: string;
    expires_at: string;
    scopes: string[]
    user: User,
}

export interface User {
    id: string;
    name: string;
    gender: string;
    document_type_id: number;
    document_code: string;
    phone_number: string;
    avatar: string;
    bio: string;
    user_id: string;
    location_id: number;
    email: string;
    username: string;
    status: string;
    verified_at: string;
    last_login_at?: string;
}