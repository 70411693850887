import { Activity } from "./activity";
import { Entity } from "./model";

@Entity({
    url: "/api/v1/activity_category"
})
export class ActivityCategory {
    id!: number;
    name!: string;
    description!: string;
    sort!: number;
    status!: number;
    activities?: Activity[];
    created_at!: string;
    created_by!: string;
    updated_at!: string;
    updated_by!: string;
    deleted_at!: string;
    deleted_by!: string;
}
