import { Component, OnInit } from '@angular/core';
import {
  CommonModule,
  DatePipe,
  Location,
} from '@angular/common'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { AuthService, LoginCredentialsI } from '../../../services/auth.service';
import { ToastService } from '../../../services/toats.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoadingService } from '../../../services/loading.service';
import { Validate } from '../../../services/validator';
import { RouteNamesService } from '../../../services/route_name.service';
import { MetaService } from '../../../services/meta.service';
import { HeaderComponent } from '../../../components/public/header/component';
import { FooterComponent } from '../../../components/public/footer/component';

@Component({
  selector: 'app-internal-login',
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  providers: [
    DatePipe,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class ClientLoginComponent implements OnInit {
  form = new FormGroup({
    email: new FormControl('', [Validate.required, Validate.minLength(3), Validate.email]),
    password: new FormControl('', [Validate.required, Validate.minLength(8)]),
  });

  model: LoginModel;

  constructor(
    private auth: AuthService,
    public toastService: ToastService,
    public loading: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private routeNames: RouteNamesService,
    private date: DatePipe,
    private meta: MetaService,
  ) {
    this.meta.title($localize`Login`);
    this.model = new LoginModel(this.form);
  }

  ngOnInit(): void {
    this.loading.hide();
    const params = this.route.snapshot.queryParams;

    if (Object.keys(params).includes("t")) {
      this.router.navigate(["/recover"], {
        "queryParams": {
          "t": params["t"],
        }
      })
    }
  }

  get email(): AbstractControl {
    return this.form.get('email') as AbstractControl;
  }

  get password(): AbstractControl {
    return this.form.get('password') as AbstractControl;
  }

  onSubmit() {
    this.loading.show();
    const scope = this.route.snapshot.data["scope"];

    this.auth.login(scope, this.form.value as LoginCredentialsI)
      .then(data => {
        if (data.user.last_login_at) {
          this.toastService.show({ header: $localize`Welcome ${data.user.name}`, body: $localize`Last login ${this.date.transform(data.user.last_login_at, "yyyy-MM-dd hh:mm:ss")}`, classname: "bg-success text-light" });
        } else {
          this.toastService.show({ header: $localize`Welcome ${data.user.name}`, body: $localize`We're excited to have you here.`, classname: "bg-success text-light" });
        }
        this.router.navigate([this.routeNames.path(this.auth.redirectTo())]);
      }, error => {
        this.toastService.show({ header: "ERROR", body: error.error?.message ?? "Error", classname: "bg-danger text-light", })
      }).finally(() => {
        this.loading.hide();
      })
  }
}

class LoginModel {
  constructor(
    public form: FormGroup<{
      email: FormControl<string | null>;
      password: FormControl<string | null>;
    }>
  ) {
    //
  }

  isInvalid(name: "email" | "password"): boolean {
    const formControl = this.form.get(name);

    if (formControl) {
      return formControl.invalid && (formControl.dirty || formControl.touched)
    }

    return false;
  }
}