import { Inject, Injectable } from "@angular/core";
import { CryptoService } from "./crypto.service";
import { DOCUMENT } from "@angular/common";

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    localStorage?: Storage;
    constructor(
        private crypto: CryptoService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.localStorage = document.defaultView?.localStorage;
    }

    set(key: string, data: any): void {
        this.localStorage?.setItem(this.crypto.encrypt(key), this.crypto.encrypt(data));
    }

    has(key: string): boolean {
        return this.localStorage?.getItem(this.crypto.encrypt(key)) != null;
    }

    get(key: string): any {
        const item = this.localStorage?.getItem(this.crypto.encrypt(key));

        if (item) {
            try {
                return this.crypto.decrypt(item)
            } catch (error) {
                
            }
        }
    }

    delete(key: string) {
        this.localStorage?.removeItem(this.crypto.encrypt(key));
    }

    clear() {
        this.localStorage?.clear();
    }
}