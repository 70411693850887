import { Component, OnInit } from '@angular/core';
import {
  CommonModule, DatePipe,
} from '@angular/common'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { AuthService, LoginCredentialsI, RegisterCredentialsI } from '../../../services/auth.service';
import { ToastService } from '../../../services/toats.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../components/public/header/component';
import { FooterComponent } from '../../../components/public/footer/component';
import { LoadingService } from '../../../services/loading.service';
import { Validate } from '../../../services/validator';
import { RouteNamesService } from '../../../services/route_name.service';
import { MetaService } from '../../../services/meta.service';

@Component({
  selector: 'app-internal-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HeaderComponent,
    FooterComponent,
    RouterModule,
  ],
  providers: [
    DatePipe,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class SupplierRegisterComponent implements OnInit {
  form = new FormGroup({
    password: new FormControl('', [Validate.required, Validate.minLength(8)]),
    email: new FormControl('', [Validate.required, Validate.email]),
    name: new FormControl('', [Validate.required, Validate.minLength(3), Validate.maxLength(124)]),
    legal_name: new FormControl('', [Validate.required, Validate.minLength(3), Validate.maxLength(124)]),
    document_type_id: new FormControl(3, [Validate.required, Validate.min(1), Validate.max(3)]),
    document_code: new FormControl('', [Validate.required, Validate.minLength(5), Validate.maxLength(10)]),
  });

  model: RegisterModel;
  // toastService = inject(ToastService);

  constructor(
    private auth: AuthService,
    public toastService: ToastService,
    public loading: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private routeNames: RouteNamesService,
    private date: DatePipe,
    private meta: MetaService,
  ) {
    this.meta.title($localize`Register`);
    this.model = new RegisterModel(this.form);
    // this.toastService.show({ header: "CONSTRUCTOR"})
  }

  ngOnInit(): void {
    this.loading.hide();
  }

  get username(): AbstractControl {
    return this.form.get('username') as AbstractControl;
  }

  get password(): AbstractControl {
    return this.form.get('password') as AbstractControl;
  }

  get name(): AbstractControl {
    return this.form.get('name') as AbstractControl;
  }

  get legal_name(): AbstractControl {
    return this.form.get('legal_name') as AbstractControl;
  }

  get document_type_id(): AbstractControl {
    return this.form.get('document_type_id') as AbstractControl;
  }

  get document_code(): AbstractControl {
    return this.form.get('document_code') as AbstractControl;
  }

  get email(): AbstractControl {
    return this.form.get('email') as AbstractControl;
  }

  onSubmit() {
    this.loading.show();
    const scope = this.route.snapshot.data["scope"];

    this.auth.register(scope, this.form.value as RegisterCredentialsI)
      .then(() => {
        this.toastService.show({ header: $localize`Almost ready to Xplore`, body: $localize`Please check your email for the account activation link.`, classname: "bg-success text-light" });
        this.router.navigate([this.routeNames.path(this.auth.redirectTo())]);
      }, error => {
        this.toastService.show({ header: "ERROR", body: error.error?.message ?? "Error", classname: "bg-danger text-light", })
      }).finally(() => {
        this.loading.hide();
      })
  }
}

class RegisterModel {
  constructor(
    public form: FormGroup<{
      password: FormControl<string | null>;
      email: FormControl<string | null>;
      name: FormControl<string | null>;
      legal_name: FormControl<string | null>;
      document_type_id: FormControl<number | null>;
      document_code: FormControl<string | null>;
    }>
  ) {
    //
  }

  isInvalid(name: "password" | "document_id" | "name" | "email" | "legal_name" | "document_type_id" | "document_code"): boolean {
    const formControl = this.form.get(name);

    if (formControl) {
      return formControl.invalid && (formControl.dirty || formControl.touched)
    }

    return false;
  }
}