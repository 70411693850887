import { ActivityBooking } from "./activity_booking";
import { ActivityPrice } from "./activity_price";

export class ActivityBookingDetail {
    id!: number;
    status!: number;
    quantity!: number;
    activity_booking_id!: number;
    booking!: ActivityBooking;
    activity_price_id!: number;
    price?: ActivityPrice;
    created_at!: string;
    created_by!: string;
    updated_at!: string;
    updated_by!: string;
    deleted_at!: string;
    deleted_by!: string;
}
