import { Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { HomeComponent } from "./pages/home/component";
import { InternalLoginComponent } from "./pages/internal_login/component";
import { GuestGuard } from "./guards/guest.guard";
import { DashboardComponent } from "./pages/suppliers/dashboard/component";
import { ActivityIndexComponent } from "./pages/suppliers/activity/index/component";
import { ActivityFormComponent } from "./pages/suppliers/activity/form/component";
import { RouteNamesService } from "./services/route_name.service";
import { ActivityDatesComponent } from "./pages/suppliers/activity/dates/component";
import { ActivityCommentsComponent } from "./pages/suppliers/activity/comments/component";
import { ActivityBookingsComponent } from "./pages/suppliers/activity/reservations/component";
import { AdminDashboardComponent } from "./pages/admin/dashboard/component";
import { SupplierIndexComponent } from "./pages/admin/supplier/index/component";
import { SupplierFormComponent } from "./pages/admin/supplier/form/component";
import { SupplierContactComponent } from "./pages/admin/supplier/contact/component";
import { SupplierAlertComponent } from "./pages/admin/supplier/alert/component";
import { SupplierCommentComponent } from "./pages/admin/supplier/comment/component";
import { ClientLoginComponent } from "./pages/clients/login/component";
import { ClientDashboardComponent } from "./pages/clients/dashboard/component";
import { BookmarkComponent } from "./pages/clients/bookmark/component";
import { ClientRegisterComponent } from "./pages/clients/register/component";
import { NotFoundComponent } from "./pages/not_found/component";
import { ClientPasswordForgotComponent } from "./pages/clients/password_forgot/component";
import { ClientPassWordRecoverComponent } from "./pages/clients/password_recover/component";
import { SearchComponent } from "./pages/clients/search/component";
import { ActivityDetailsComponent } from "./pages/clients/activity/component";
import { CartComponent } from "./pages/clients/cart/component";
import { ActivityImageComponent } from "./pages/suppliers/activity/images/component";
import { ActivityAttributesComponent } from "./pages/suppliers/activity/attributes/component";
import { ActivityFAQComponent } from "./pages/suppliers/activity/faq/component";
import { ActivityUnsuitablesComponent } from "./pages/suppliers/activity/unsuitables/component";
import { ActivityHighlightsComponent } from "./pages/suppliers/activity/highlights/component";
import { ActivityPricesComponent } from "./pages/suppliers/activity/prices/component";
import { SupplierLoginComponent } from "./pages/suppliers/login/component";
import { SupplierRegisterComponent } from "./pages/suppliers/register/component";
import { SuppliersProfileComponent } from "./pages/suppliers/profile/component";
import { OrderComponent } from "./pages/clients/order/component";
import { SupplierHomeComponent } from "./pages/suppliers/home/component";
import { ComingSoonComponent } from "./pages/coming_soon/component";

export const routes: Routes = [
    {
        path: "",
        component: HomeComponent,
        data: {
            name: "home",
        },
    },
    {
        path: "activity/:id/:slug",
        component: ActivityDetailsComponent,
        data: {
            name: "details",
        },
    },
    {
        path: "search",
        component: SearchComponent,
        data: {
            name: "search",
            scope: "client",
        },
    },
    {
        path: "login",
        component: ClientLoginComponent,
        canActivate: [GuestGuard],
        data: {
            name: "login",
            scope: "client",
        },
    },
    {
        path: "register",
        component: ClientRegisterComponent,
        canActivate: [GuestGuard],
        data: {
            name: "register",
            scope: "client",
        },
    },
    {
        path: "forgot",
        component: ClientPasswordForgotComponent,
        canActivate: [GuestGuard],
        data: {
            name: "forgot",
            scope: "client",
        }
    },
    {
        path: "recover",
        component: ClientPassWordRecoverComponent,
        canActivate: [GuestGuard],
        data: {
            name: "recover",
            scope: "client",
        }
    },
    {
        path: "home",
        component: HomeComponent,
        data: {
            name: "dashboard",
            scope: "client",
        },
    },
    {
        path: "profile",
        component: ClientDashboardComponent,
        data: {
            name: "profile",
            scope: "client",
        },
    },
    {
        path: "wishlists",
        component: BookmarkComponent,
        canActivate: [AuthGuard],
        data: {
            name: "bookmark",
            scope: "client",
        },
    },
    {
        path: "bookings",
        canActivate: [AuthGuard],
        data: {
            name: "bookings",
            scope: "client",

        },
        children: [
            {
                path: "",
                component: OrderComponent,
                data: {
                    name: "bookings",
                    tag: "unpaid",
                    scope: "client",
                },
            },
            {
                path: "unpaid",
                component: OrderComponent,
                data: {
                    name: "unpaid",
                    tag: "unpaid",
                    scope: "client",
                },
            },
            {
                path: "processing",
                component: OrderComponent,
                data: {
                    name: "processing",
                    tag: "processing",
                    scope: "client",
                },
            },
            {
                path: "processed",
                component: OrderComponent,
                data: {
                    name: "processed",
                    tag: "processed",
                    scope: "client",
                },
            },
            {
                path: "cancelled",
                component: OrderComponent,
                data: {
                    name: "cancelled",
                    tag: "cancelled",
                    scope: "client",
                },
            },
        ]
    },
    {
        path: "shopping-cart",
        component: CartComponent,
        canActivate: [AuthGuard],
        data: {
            name: "cart",
            scope: "client",
        },
    },
    {
        path: "about-us",
        component: ComingSoonComponent,
        data: {
            name: "about_us",
            scope: "client",

        },
    },
    {
        path: "conditions",
        component: ComingSoonComponent,
        data: {
            name: "conditions",
            scope: "client",

        },
    },
    {
        path: "policies",
        component: ComingSoonComponent,
        data: {
            name: "policies",
            scope: "client",

        },
    },
    {
        path: "help",
        component: ComingSoonComponent,
        data: {
            name: "help",
            scope: "client",

        },
    },
    {
        path: "download",
        component: ComingSoonComponent,
        data: {
            name: "download",
            scope: "client",

        },
    },


    // PROVIDERS ADMINISTRATION
    {
        path: "suppliers",
        data: {
            name: "suppliers"
        },
        children: [
            {
                path: "",
                component: SupplierHomeComponent,
                data: {
                    name: "index",
                    scope: "supplier",
                },
            },
            {
                path: "dashboard",
                component: DashboardComponent,
                canActivate: [AuthGuard],
                data: {
                    name: "dashboard",
                    scope: "supplier",
                },
            },
            {
                path: "profile",
                component: SuppliersProfileComponent,
                canActivate: [AuthGuard],
                data: {
                    name: "profile",
                    scope: "supplier",
                },
            },
            {
                path: "login",
                component: SupplierLoginComponent,
                canActivate: [GuestGuard],
                data: {
                    name: "login",
                    scope: "supplier",
                },
            },
            {
                path: "register",
                component: SupplierRegisterComponent,
                canActivate: [GuestGuard],
                data: {
                    name: "register",
                    scope: "supplier",
                },
            },
            {
                path: "activity",
                canActivate: [AuthGuard],
                data: {
                    name: "activity",
                    scope: "supplier",
                },
                children: [
                    {
                        path: "",
                        component: ActivityIndexComponent,
                        data: {
                            name: "index",
                        },
                    },
                    {
                        path: "create",
                        component: ActivityFormComponent,
                        data: {
                            name: "create",
                        },
                    },
                    {
                        path: ":id/update",
                        component: ActivityFormComponent,
                        data: {
                            name: "update",
                        },
                    },
                    {
                        path: ":id/details",
                        component: ActivityAttributesComponent,
                        data: {
                            name: "details",
                        },
                    },
                    {
                        path: ":id/highlights",
                        component: ActivityHighlightsComponent,
                        data: {
                            name: "highlights",
                        },
                    },
                    {
                        path: ":id/faq",
                        component: ActivityFAQComponent,
                        data: {
                            name: "faq",
                        },
                    },
                    {
                        path: ":id/images",
                        component: ActivityImageComponent,
                        data: {
                            name: "images",
                        },
                    },
                    {
                        path: ":id/prices",
                        component: ActivityPricesComponent,
                        data: {
                            name: "prices",
                        },
                    },
                    {
                        path: ":id/unsuitables",
                        component: ActivityUnsuitablesComponent,
                        data: {
                            name: "unsuitables",
                        },
                    },
                    {
                        path: ":id/dates",
                        component: ActivityDatesComponent,
                        data: {
                            name: "dates",
                        },
                    },
                    {
                        path: ":id/comments",
                        component: ActivityCommentsComponent,
                        data: {
                            name: "comments",
                        },
                    },
                    {
                        path: ":id/reservations",
                        component: ActivityBookingsComponent,
                        data: {
                            name: "reservations",
                        },
                    },
                ],
            },
        ]
    },

    // MAIN ADMINISTRATION
    {
        path: "admin",
        data: {
            name: "admin"
        },
        children: [
            {
                path: "dashboard",
                component: AdminDashboardComponent,
                canActivate: [AuthGuard],
                data: {
                    name: "dashboard",
                    scope: "admin",
                },
            },
            {
                path: "login",
                component: InternalLoginComponent,
                canActivate: [GuestGuard],
                data: {
                    name: "login",
                    scope: "admin",
                },
            },
            {
                path: "supplier",
                canActivate: [AuthGuard],
                data: {
                    name: "supplier",
                    scope: "admin",
                },
                children: [
                    {
                        path: "",
                        component: SupplierIndexComponent,
                        data: {
                            name: "index",
                        },
                    },
                    {
                        path: "create",
                        component: SupplierFormComponent,
                        data: {
                            name: "create",
                        },
                    },
                    {
                        path: ":id/update",
                        component: SupplierFormComponent,
                        data: {
                            name: "update",
                        },
                    },
                    {
                        path: ":id/contacts",
                        component: SupplierContactComponent,
                        data: {
                            name: "contacts",
                        }
                    },
                    {
                        path: ":id/alerts",
                        component: SupplierAlertComponent,
                        data: {
                            name: "alerts",
                        }
                    },
                    {
                        path: ":id/comments",
                        component: SupplierCommentComponent,
                        data: {
                            name: "comments",
                        }
                    }
                ],
            },
        ]
    },

    // NOT FOUND
    {
        path: "**",
        component: NotFoundComponent,
    }
];

RouteNamesService.routes = routes;
