<app-header color="text-main" />

<main class="min-vh-100 position-relative">
  @if (model.attributes && model.attributes.status == 0) {
  <div class="position-absolute h-100 w-100 z-3">
    <div class="pending h-100 w-100 border border-danger"></div>
  </div>
  }

  <section class="pb-5 pt-3 bg-white border-top pt-4" *ngIf="model?.attributes">
    <div class="container-lg">
      <div class="row">
        @if (model.attributes.status == 0) {
        <span class="px-3 py-2 mb-3 border rounded bg-danger text-white text-center">
          <i class="fa-solid fa-circle-exclamation pe-2"></i>
          <span class="text-uppercase" i18n>Pendiente por Aprobación</span>
          <i class="fa-solid fa-circle-exclamation ps-2"></i>
        </span>
        }

        <article class="col-md-12">
          <div class="row gy-3 justify-content-between align-items-end">
            <div class="col-auto">
              <div class="row gx-2 gy-2 items-center">
                @for (tag of model.attributes.tags; track tag.id) {
                  <div class="col-auto">
                    <button class="btn btn-outline-main px-3 small-2 ls-md rounded-5 text-hover-white">
                      {{ locale.getTagName(tag.name) }}
                    </button>
                  </div>
                }
              </div>
              <h1 class="mt-4 text-black">
                {{ model.attributes.display_title }}
              </h1>
              <div class="row g-3 align-items-end pt-3 small fw-light">
                <div class="col-auto">
                  <div class="d-flex items-center">
                    <rating [rating]="model.attributes.rating" />
                    <span>&nbsp;</span>
                    <span>{{ model.attributes.rating | number:'1.1-1' }}</span>
                    <span>/5</span>
                    <span>&nbsp;</span>
                    <span class="pe-1">({{ model.attributes.comments_count | number }})</span>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="d-flex align-items-center">
                    <span class="text-muted"><i class="fa-solid fa-location-dot"></i></span>
                    <span>&nbsp;</span>
                    <span>{{ model.attributes.destination.name }}</span>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="d-flex items-center">
                    <span class="text-muted"><i class="fa-solid fa-users"></i></span>
                    <span>&nbsp;</span>
                    <span>{{ model.attributes.participant_count | number }}</span>
                    <span>&nbsp;</span>
                    <span>participantes</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-auto">
              <div class="d-flex gx-4 gy-2">
                <a href="#" class="d-flex align-items-center">
                  <i class="fa-solid fa-share"></i>
                  Share
                </a>
                <a href="#" class="d-flex align-items-center">
                  <i class="fa-regular fa-heart"></i>
                  Wishlist
                </a>
              </div>
            </div> -->
          </div>

          <div class="container-fluid my-4 px-0 overflow-hidden rounded-4">
            <div class="row g-2">
              <div [ngClass]="getImageClass(0, prepareImages().last.length == 0)">
                <img class="img-fluid h-100 w-100" [src]="prepareImages().main?.path"
                  [alt]="prepareImages().main?.description ?? model.attributes.display_title" rel="preload"
                  loading="eager" fetchpriority="high">
              </div>
              <div class="col-5">
                <div class="row g-2">
                  @for (image of prepareImages().last; track image.id;) {
                  <div class="overflow-hidden" [ngClass]="getImageClass($index + 1, $last)">
                    <img class="img-fluid h-100 w-100" [src]="image.path"
                      [alt]="image.description ?? model.attributes.display_title" rel="preload" [title]="$index"
                      loading="eager">
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8 p-3 px-md-2">
              <h2>Descripción</h2>
              <div class="small" [innerHTML]="model.attributes.description"></div>
              <!-- <h3 class="fs-6 fw-500">Resumen</h3>
              <ul class="small-2">
                @for (h of highlights; track h.id) {
                <li class="lh-lg">
                  {{ h?.description }}
                </li>
                }
              </ul> -->
              <hr class="my-5">
              <h2>¿Qué incluye?</h2>
              <div class="row gx-5">
                @for (attr of attributes; track attr.id) {
                <div class="col-md-6 small mb-3 h-100 d-flex  justify-content-start align-items-top">
                  @if (attr?.value) {
                  <span class="col-1 icon bg-success-subtle d-flex justify-content-center align-items-center">
                    <i class="text-success fa-solid fa-check"></i>
                  </span>
                  }
                  @else {
                  <span class="col-1 icon bg-danger-subtle d-flex justify-content-center align-items-center">
                    <i class="text-danger fa-solid fa-xmark"></i>
                  </span>
                  }
                  <span class="col ms-2"><b>{{ attr?.title }}</b> - {{ attr?.description }}</span>
                </div>
                }
              </div>
              <h2 class="mt-4 mb-2">¿Qué harás?</h2>
              <div class="border-main" style="border-left: 2px dashed;">
                @for (h of highlights; track h.id) {
                <div class="row mb-4 small">
                  <span class="col-1 icon bg-main d-flex justify-content-center align-items-center"
                    style="margin-left: -2px;">
                    @if ($first) {
                    <i class="text-white fa-solid fa-location-dot"></i>
                    }
                    @else if ($last) {
                    <i class="text-white fa-solid fa-flag-checkered"></i>
                    }
                    @else {
                    <!-- <i class="text-white fa-solid fa-circle"></i> -->
                    }
                  </span>
                  <span class="col text-muted" style="min-height: 32px;">{{ h.description }}</span>
                </div>
                }
              </div>
              <hr class="my-5">
              <h2 class="mt-4 mb-2">No Apto Para</h2>
              <div class="row gx-5">
                @for (h of unsuitables; track h.id) {
                <div class="col-md-6 small mb-3 h-100 d-flex  justify-content-start align-items-top">
                  <span class="col-1 icon bg-danger-subtle d-flex justify-content-center align-items-center">
                    <i class="text-danger fa-solid fa-ban"></i>
                  </span>
                  <span class="col ms-2 fw-bold">{{ h?.title }}</span>
                </div>
                }
              </div>
              <hr class="my-5">
              <h2 class="my-4">Preguntas Frecuentes</h2>
              <div class="row px-3">
                @for (q of questions; track q.id) {
                <div class="col-12 border rounded-3 px-4 py-3 mb-4">
                  <div class="d-flex justify-content-between align-items-top">
                    <div class="fw-bold pe-4">{{ q?.title }}</div>

                    <span class="col-1 icon bg-main d-flex justify-content-center align-items-center">
                      <i class="text-white small fa-solid fa-minus"></i>
                    </span>
                    <!-- <span> -->
                    <!-- <i class="icon fs-5 bg-main text-white fa-solid fa-minus"></i> -->
                    <!-- <i class="icon fs-5 bg-main-subtle text-main fa-solid fa-plus"></i> -->
                    <!-- </span> -->
                  </div>
                  <div class="pt-3 text-muted small">
                    {{ q?.description }}
                  </div>
                </div>
                }
              </div>
              <hr class="my-5">
              <h2 class="mt-4">Opiniones</h2>
              <p class="mb-4 fst-italic text-muted">
                Todas las opiniones se corresponden con personas reales que reservaron con nosotros.
              </p>
              @for (c of model.attributes.comments; track c.id) {
              <div class="pt-4">
                <div class="row align-items-center justify-content-between">
                  <div class="col-auto">
                    <div class="d-flex align-items-center">
                      <div class="col-1 icon small bg-dark-subtle d-flex justify-content-center align-items-center">
                        <i class="text-white fa-solid fa-user"></i>
                      </div>
                      <div class="fw-500 ms-3">{{ c?.person?.name }}</div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="text-muted text-capitalize small text-muted">
                      {{ c?.created_at | date:'MMMM YYYY' }}
                    </div>
                  </div>
                </div>
                <div class="d-flex items-center mt-15">
                  <div class="d-flex">
                    <rating [rating]="c.rating" />
                  </div>
                  <div class="fw-bold ms-2">{{ c?.title }}</div>
                </div>
                <p class="mt-2">{{ c?.message }}</p>
              </div>
              }
            </div>

            <div class="col-md-4 p-0 px-md-2">
              <div class="card">
                @if (model.attributes.regularity_id == 1) {
                <div class="card-header text-center bg-main text-white">
                  <h3 class="fw-900" i18n>SUBSCRIBE</h3>
                  @if (model.attributes.base_price > 0) {
                  <p class="mb-0 fw-bold" i18n>Estimated price</p>
                  <p>
                    {{ model.attributes.base_price | number:'2.2-2' }}
                    Ref
                  </p>
                  }
                </div>
                <div class="card-body">
                  <p class="text-muted" i18n>
                    We will let you know as soon as we have available dates for this activity.
                  </p>
                  @if (isBrowser) {
                  @if (auth.user) {
                  @if (model.attributes.bookmarked) {
                  <button class="btn btn-success w-100 text-center" disabled type="button">
                    <i class="fa-solid fa-circle-check pe-2"></i>
                    <span i18n>Already subscribed</span>
                  </button>
                  }
                  @else {
                  <button class="btn btn-main w-100 text-center" type="button" (click)="bookmark(model.attributes)"
                    style="--bs-btn-hover-color: var(--bs-light); --bs-btn-color: var(--bs-white)">
                    <i role="button" class="fa-regular fa-heart pe-2"></i>
                    <span i18n>Subscribe</span>
                  </button>
                  }
                  }
                  @else {
                  <a class="btn btn-outline-main fw-bold w-100 text-center" routerLink="/login"
                    style="--bs-btn-hover-color: var(--bs-light);" i18n>
                    Login
                  </a>
                  }
                  }
                </div>
                }
                @else {
                <div class="card-header text-center bg-main text-white">
                  <h3 class="fw-900" i18n>BOOK ONLINE</h3>
                  <p>{{ model.attributes.base_price | number:'2.2-2' }} Ref</p>
                </div>
                <div class="card-body">
                  <h4 class="mb-0" i18n>Date</h4>
                  @if (model.attributes.regularity_id == 2) {
                  <p class="fst-italic mb-0 fw-bold">
                    <i class="fa-solid fa-circle-exclamation pe-2 text-warning"></i>
                    <span class="text-muted">This is an on-demand activity</span>
                    <i class="fa-solid fa-circle-exclamation ps-2 text-warning"></i>
                  </p>
                  <p class="text-muted fst-italic small" i18n>
                    Please choose the date you would like to participate in this activity.
                  </p>
                  }
                  @else {
                  <p class="text-muted fst-italic small" i18n>Please choose one of the available dates for this
                    activity.
                  </p>
                  }
                  <app-calendar [open]="openDays" (selected)="onSelectDate($event)" />
                </div>
                <div class="card-body" *ngIf="dateSelected">
                  <form class="form" novalidate [formGroup]="booking" (ngSubmit)="addToCart()">
                    <hr>
                    <h4 class="mb-0" i18n>Schedule</h4>
                    @if (model.attributes.regularity_id == 2) {
                    <p class="fst-italic fw-bold">
                      <i class="fa-solid fa-circle-exclamation pe-2 text-warning"></i>
                      <span class="text-muted">We will contact you.</span>
                      <i class="fa-solid fa-circle-exclamation ps-2 text-warning"></i>
                    </p>
                    }
                    @else {
                    <p class="text-muted fst-italic small" i18n>Please choose one of the available schedules for this
                      activity.</p>
                    <select class="form-select" name="time" id="time" formControlName="activity_time_id">
                      <option value="">Select one</option>
                      @for (time of availableTimes; track time.id) {
                      <option [value]="time.id">{{ time.starts_at }} - {{ time.ends_at }} </option>
                      }
                    </select>
                    }
                    <hr>
                    <h4 class="mb-0" i18n>Participants</h4>
                    @if (model.attributes.regularity_id == 2) {
                    <p class="fst-italic fw-bold mb-0">
                      <i class="fa-solid fa-circle-exclamation pe-2 text-warning"></i>
                      <span class="text-muted">Max participants {{ model.attributes.expected_participants }}</span>
                      <i class="fa-solid fa-circle-exclamation ps-2 text-warning"></i>
                    </p>
                    <p class="text-muted fst-italic small">Select the number of participants</p>
                    <hr>
                    <div class="input-group input-group-sm mb-1">
                      <span class="col w-100 input-group-text">Una persona</span>
                      <span class="input-group-text">{{ model.attributes.base_price | number:'2.2-2' }}</span>
                      <button class="btn btn-outline-secondary" type="button" (click)="setParticipants(1, 1)">
                        <i class="fa-solid fa-plus"></i>
                      </button>
                      <span class="input-group-text">{{ selectedPrices[1].amount | number:'2.2-2' }}</span>
                      <button class="btn btn-outline-secondary" type="button" (click)="setParticipants(1, -1)">
                        <i class="fa-solid fa-minus"></i>
                      </button>
                    </div>
                    }
                    @else {
                    <p class="text-muted fst-italic small">Select the number of participants</p>
                    <p class="small mb-0" i18n>Expected participants {{ timeSelected?.expected_participants }}</p>
                    <p class="small">
                      <span [ngClass]="timeSelected && timeSelected.current_available <= 5 ? 'text-danger' : ''" i18n>
                        Current availability: {{ timeSelected?.current_available }}
                      </span>
                    </p>
                    <hr>
                    @for (price of availablePrices; track price.id) {
                    <div class="input-group input-group-sm mb-1">
                      <span class="col w-100 input-group-text">{{ price.title }}</span>
                      <span class="input-group-text">{{ price.price | number:'2.2-2' }}</span>
                      <button class="btn btn-outline-secondary" type="button" (click)="setParticipants(price.id, 1)">
                        <i class="fa-solid fa-plus"></i>
                      </button>
                      <span class="input-group-text">{{ selectedPrices[price.id].amount }}</span>
                      <button class="btn btn-outline-secondary" type="button" (click)="setParticipants(price.id, -1)">
                        <i class="fa-solid fa-minus"></i>
                      </button>
                    </div>
                    }
                    }
                    <hr>
                    <p>{{ dateSelected.starts_at | date:"mediumDate" }} - {{ timeSelected?.starts_at }}</p>
                    <h4 i18n>Total price</h4>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th class="w-50" i18n>Each</th>
                          <th i18n>Participants</th>
                          <th class="w-50" i18n>Total price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-end">
                            {{ (summary.price == 0 ? 0 : summary.price / summary.participants) | number:'0.2-2' }}
                          </td>
                          <td class="text-end">{{ summary.participants }}</td>
                          <td class="fw-bold text-end">{{ summary.price | number:'0.2-2' }}</td>
                        </tr>
                      </tbody>
                    </table>
                    @if (auth.user) {
                    <button class="btn btn-outline-main w-100 text-center fw-bold" type="button" (click)="addToCart()"
                      style="--bs-btn-hover-color: var(--bs-light);" i18n>
                      Add to cart
                    </button>
                    }
                    @else {
                    <a class="btn btn-outline-main w-100 text-center fw-bold" routerLink="/login"
                      style="--bs-btn-hover-color: var(--bs-light);" i18n>
                      Login
                    </a>
                    }
                  </form>
                </div>
                }
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</main>

<app-footer />