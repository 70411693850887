import { registerLocaleData } from "@angular/common";
import { Injectable } from "@angular/core";
import localeEnglish from '@angular/common/locales/en';
import localeSpanish from '@angular/common/locales/es';

@Injectable({ providedIn: 'root' })
export class LocaleService{

    private _locale!: string;

    set locale(value: string) {
        this._locale = value;
    }

    get locale(): string {
        return this._locale || 'es';
    }

    constructor() {
        this.setLocaleData("es");
    }

    setLocaleData(locale: string) {
        this.locale = locale;

        switch (locale) {
            case 'en': {
                registerLocaleData(localeEnglish, "en");
                break;
            }
            case 'es': {
                registerLocaleData(localeSpanish, "es", );
                break;
            }
        }
    }

    getTagName(name: string): string {
        let result = '';

        switch (name) {
            case "new":
                result = $localize`New`;
                break;
            case "recommended":
                result = $localize`Recommended`;
                break;
            case "inexpensive":
                result = $localize`Inexpensive`;
                break;
            case "featured":
                result = $localize`Featured`;
                break;

            default:
                result = name;
                break;
        }

        return result;
    }
}