<div class="container-fluid min-vh-100 p-0">
  <div class="d-flex">
    <navbar></navbar>

    <div class="bg-light flex-grow-1">
      <menu></menu>
      <main class="container-fluid p-5">
        <h1 class="fs-2" i18n>Wishlists</h1>
        <!-- <p>Descripción</p> -->
        <div class="border rounded-4 bg-white mt-5 p-5">
          <div class="row row-cols-1 g-3 ps-3">
            @if (model.items.length > 0) {
              @for (item of model.items; track item.id) {
                <card rectangle [item]="item"></card>
              }
            }
            @else {
              <card rectangle *ngFor="let number of [1,2,3,4]"></card>
            }
          </div>
        </div>
      </main>
      <footer></footer>
    </div>
  </div>
</div>