<app-header />

<main>
  <div class="container my-5">
    <div class="row">
      <form class="form needs-validation" novalidate [formGroup]="form" (ngSubmit)="onSubmit()">
        <h3 class="fw-normal mb-3 pb-3 text-center">Register</h3>
        <div class="offset-md-2 col-md-4 bg-white p-3">

          <label for="document_code" class="form-label text-muted" i18n>Document Number</label>
          <div class="input-group input-group-lg mb-3">
            <select class="form-select" id="document_type_id" formControlName="document_type_id">
              <option value="1">Cédula</option>
              <option value="2">Pasaporte</option>
              <option value="3">RIF</option>
            </select>
            <input type="text" id="document_code" class="form-control"
              [ngClass]="model.isInvalid('document_code') ? 'is-invalid' : ''" formControlName="document_code" required
              i18n-placeholder placeholder="Document" autocomplete="document_code" maxlength="10">

            <div *ngIf="model.isInvalid('document_code')" id="document_code-feedback" class="invalid-feedback">
              <div *ngIf="document_code.errors?.['required']" i18n>Document Number is required</div>
            </div>
          </div>

          <div class="form-floating mb-3">
            <input type="text" id="name" class="form-control" [ngClass]="model.isInvalid('name') ? 'is-invalid' : ''"
              formControlName="name" required i18n-placeholder placeholder="Username" autocomplete="name">
            <label for="name" i18n>Name</label>

            <div *ngIf="model.isInvalid('name')" id="name-feedback" class="invalid-feedback">
              <div *ngIf="name.errors?.['required']" i18n>Name is required</div>
            </div>
          </div>

          <div class="form-floating mb-3">
            <input type="text" id="legal_name" class="form-control"
              [ngClass]="model.isInvalid('legal_name') ? 'is-invalid' : ''" formControlName="legal_name" required
              i18n-placeholder placeholder="Legal Name" autocomplete="legal_name">
            <label for="legal_name" i18n>Legal Name</label>

            <div *ngIf="model.isInvalid('legal_name')" id="legal_name-feedback" class="invalid-feedback">
              <div *ngIf="legal_name.errors?.['required']" i18n>Legal Name is required</div>
            </div>
          </div>
          <hr>
          <div class="form-floating mb-3">
            <input type="text" id="email" class="form-control" [ngClass]="model.isInvalid('email') ? 'is-invalid' : ''"
              formControlName="email" required i18n-placeholder placeholder="Username" autocomplete="email">
            <label for="email" i18n>Email</label>

            <div *ngIf="model.isInvalid('email')" id="email-feedback" class="invalid-feedback">
              <div *ngIf="email.errors?.['required']" i18n>Email is required</div>
            </div>
          </div>

          <div class="form-floating mb-3">
            <input type="password" id="password" class="form-control"
              [ngClass]="model.isInvalid('password') ? 'is-invalid' : ''" formControlName="password" required
              i18n-placeholder placeholder="Password" autocomplete="new-password">
            <label for="password" i18n>Password</label>

            <div *ngIf="model.isInvalid('password')" id="password-feedback" class="invalid-feedback">
              <div *ngIf="password.errors?.['required']" i18n>Password is required</div>
              <div *ngIf="password.errors?.['minlength']" i18n>Password must be at least 8 characters long.</div>
            </div>
          </div>

          <div class="pt-1 mb-4">
            <button class="btn btn-outline-primary " type="submit" [disabled]="!form.valid || loading.isLoading" i18n>
              Register
            </button>
          </div>

          <p>
            <span i18n>Already have an account?</span>
            &nbsp;
            <a routerLink="/suppliers/login" class="link-info" i18n>Login here</a>
          </p>
        </div>

      </form>
    </div>
  </div>
</main>

<app-footer />