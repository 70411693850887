import {
  Component,
} from '@angular/core';
import {
  CommonModule, DatePipe,
} from '@angular/common'
import {
  ReactiveFormsModule,
} from '@angular/forms';
import { MetaService } from '../../../services/meta.service';
import { RouterModule } from '@angular/router';
import { ClientNavbar } from '../../../components/client/sidebar';
import { ClientHeader } from '../../../components/client/header';
import { ClientFooter } from '../../../components/client/footer';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ClientNavbar,
    ClientHeader,
    ClientFooter,
  ],
  providers: [
    DatePipe,
  ],
  templateUrl: './component.html',
  styleUrl: './component.scss'
})
export class ClientDashboardComponent {
  constructor(
    private meta: MetaService,
  ) {
    this.meta.title($localize `Dashboard`);
  }
}
