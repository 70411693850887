<div class="modal-header">
  <h1 class="fs-5">Pagar con Pago Inmediato - #{{ reference.toString().padStart(8, "0") }}</h1>
</div>
<div class="modal-body">
  <p>Pague en 3 simples pasos:</p>
  <ol>
    <li>Introduzca sus datos y haga click en "Continuar"</li>
    <li>Su banco le enviará una Clave de Pago</li>
    <li>Introduzca la clave de pago suministrada y haga click en finalizar</li>
  </ol>
  <div class="form-responsive">
    <form class="form needs-validation" novalidate [formGroup]="form">

      @if (requestOTP) {
      <div class="form-floating mb-3">
        <input type="text" class="form-control" id="name" name="name" placeholder="Nombre" formControlName="name"
          [ngClass]="isInvalid('name') ? 'is-invalid' : ''" mask="A*" [patterns]="namePattern">
        <label for="name">Nombre o Razón Social</label>
      </div>

      <span>Identificación</span>
      <div class="mb-3 row gx-0">
        <div class="form-floating col-4">
          <select class="form-select" name="id_type" id="id_type"
            [ngClass]="isInvalid('id_type') ? 'is-invalid' : ''" formControlName="id_type">
            <option value="SCID">Cédula</option>
            <option value="SPAS">Pasaporte</option>
            <option value="SRIF">RIF</option>
          </select>
          <label for="id_type">Tipo</label>
        </div>
        <div class="form-floating col-8">
          <input type="text" class="form-control" id="identifier" name="identifier" placeholder="Identificación"
            [ngClass]="isInvalid('identifier') ? 'is-invalid' : ''" formControlName="identifier" mask="separator.0"
            thousandSeparator=".">
        </div>
      </div>

      <div class="form-floating mb-3">
        <input type="text" class="form-control" id="account" name="account" placeholder="Cuenta" formControlName="account"
          [ngClass]="isInvalid('account') ? 'is-invalid' : ''" mask="0000-000-0000||0000-0000-0000-0000-0000"
          [showMaskTyped]="true">
        <label class="w-100" for="account">Cuenta o Teléfono</label>
        <div class="form-text" id="basic-addon4">Ejemplo (0424 123 4567) o (0169-1234-5678-9012-3456).</div>
      </div>

      <div class="form-floating mb-3">
        <select name="bank" id="bank" class="form-select" [ngClass]="isInvalid('bank') ? 'is-invalid' : ''"
          formControlName="bank">
          @for (bank of banks; track bank.code) {
          <option [value]="bank.code">{{ bank.code }} - {{ bank.display_name }}</option>
          }
        </select>
      </div>
      }
      @else {
      <div class="form-floating mb-3">
        <input type="text" class="form-control" id="otp" name="otp" placeholder="Nombre" formControlName="otp"
          [ngClass]="isInvalid('otp') ? 'is-invalid' : ''" mask="0*" >
        <label for="otp">Clave de Pago</label>
      </div>
      }
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancelar</button>
    @if (requestOTP) {
      <button type="button" ngbAutofocus class="btn btn-primary" (click)="sendOtpRequest()" [disabled]="!form.valid">Continuar</button>
    }
    @else {
      <button type="button" ngbAutofocus class="btn btn-success" (click)="sendTransactionRequest()" [disabled]="!form.valid">Finalizar</button>
    }
  </div>
</div>